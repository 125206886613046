import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Banner from '../shared/assets/service-banner.jpg'
import { DEALERNAME } from '../shared/Constants'

const useStyles = makeStyles((theme) => ({
  bannerHeader: {
    backgroundColor: '#fc3',
    fontWeight: 700
  },
  info: {
    paddingBottom: '1rem'
  }
}));

const ServicePlansPage = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
        Maintenance & Service Plans
      </Typography>
      <Box mb={2}>
        <img style={{ width: '100%' }} alt="Tom Tom" src={Banner} />
      </Box>
      <Typography gutterBottom variant="h6" color="textPrimary" fontWeight="500">
        What is a Maintenance Plan?
      </Typography>
      <div className={classes.info}>
        <Box>
          <p>A Maintenance Plan is a comprehensive package that covers all services, maintenance, normal wear and tear requirements and labour.
            This means that you will never have to worry about unexpected parts and labour costs again.</p>
        </Box>
      </div>
      <div className={classes.info}>
        <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
          Maintenance Plan: What is covered?
        </Box>
        <Box>
          <p>Warranty related items, Top-Up Oils, Driver abuse, Windscreen & all glass, Punctures, Paintwork, Body damage, Tyres.</p>
        </Box>
      </div>
      <div className={classes.info}>
        <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
          The Benefits of Maintenance Plans
        </Box>
        <Box>
          <ul>
            <li>Extensions can be “tailor-made” to suit your requirements</li>
            <li>You’ll be paying for tomorrow’s motoring costs at today’s prices.</li>
            <li>We’ll keep your full service history, which will assist in maximizing the resale value of your value</li>
            <li>Any unexpected maintenance your vehicle may need in the future will be taken care of.</li>
          </ul>
        </Box>
      </div>
      <div className={classes.info}>
        <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
          What is a Service Plan?
        </Box>
        <Box>
          <p>Service Plans are designed to provide the client with the assurance that all scheduled services will be covered as per their Owners Manual,
            by an approved {DEALERNAME}  dealer, utilizing approved parts fitted by highly qualified service technicians.</p>
        </Box>
      </div>
      <div className={classes.info}>
        <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
          Service Plans: What is Covered?
        </Box>
        <Box>
          <p>Service plans cover all scheduled services as specified in the Owners Manual.</p>
          <p>This includes the replacement of:<br />
            Engine and transmission oil, spark plugs. All serviceable belts. Most importantly cambelt*, Brake fluid, Oil filters, Air filters, Sump plug
            gaskets, Other lubricants and serviceable items required to perform the scheduled service, All labour costs involved in the scheduled service.</p>
        </Box>
      </div>
      <div className={classes.info}>
        <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
          Service Plans: What is not covered?
        </Box>
        <Box>
          <p>Top up lubricants, such as oil, replacement of all wear and tear items, such as: brake pads, brake disks, shock absorbers, exhausts, tyres,
            wheel alignment, and balancing. Other market related exclusions: glass, towbars, punctures, radio / tape / cd, aircon regassing, alloy wheels.</p>
        </Box>
      </div>
      <div className={classes.info}>
        <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
          The benefits of Service Plans
        </Box>
        <Box>
          <ul>
            <li>Clients can purchase a Service Plan at any time.</li>
            <li>You don’t feel the pinch of regular servicing costs.</li>
            <li>Your servicing costs are fixed. No matter how high prices rise in the future, you won’t have to worry about inflation, parts or labour
              cost increases.
            </li>
            <li>Your vehicle’s warranty will never be in jeopardy.</li>
          </ul>
        </Box>
      </div>
    </React.Fragment>
  )
}

export default ServicePlansPage;