import React from 'react';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink as RouterLink } from 'react-router-dom';
import Carousel from '../homePage/Carousel';
import Typography from '@material-ui/core/Typography';
import { DEALERNAME } from '../shared/Constants'

const useStyles = makeStyles((theme) => ({
  link: {
    backgroundColor: '#fc3',
    marginBottom: '0.5rem',
    '& > a': {
      color: '#7c7c7c',
      fontWeight: '700',
      '&:hover': {
        color: '#1f2532',
        textDecoration: 'none',
      },
    }
  }
}));

const Navigation = () => {
  const classes = useStyles();
  const slides = [
    { img: 'https://source.unsplash.com/hb8fkAWx9gc/356x200' },
    { img: 'https://source.unsplash.com/hb8fkAWx9gc/356x200' },
    { img: 'https://source.unsplash.com/hb8fkAWx9gc/356x200' }
  ]

  return (
    <React.Fragment>
      <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
        New Car Models
      </Typography>
      <Box mb={1}>
        <Carousel naturalSlideWidth={100}
          isPlaying={true}
          naturalSlideHeight={60}
          totalSlides={slides.length}
          visibleSlides={1}
          infinite
          slides={slides}
          dots
        />
      </Box>
      <Box pt={1} pb={1} className={classes.link} textAlign="center">
        <Link component={RouterLink} to='/warranty'>5 Year Warranty</Link>
      </Box>
      <Box pt={1} pb={1} className={classes.link} textAlign="center">
        <Link component={RouterLink} to='/service-plans'>Service Plans</Link>
      </Box>
      <Box pt={1} pb={1} className={classes.link} textAlign="center">
        <Link component={RouterLink} to='/maintenance'>Maintain your {DEALERNAME} </Link>
      </Box>
      <Box pt={1} pb={1} className={classes.link} textAlign="center">
        <Link component={RouterLink} to='/tomtom-live'>TOMTOM Live Support</Link>
      </Box>
      <Box pt={1} pb={1} className={classes.link} textAlign="center">
        <Link component={RouterLink} to='/bodyshops'>Approved Bodyshops</Link>
      </Box>
      <Box pt={1} pb={1} className={classes.link} textAlign="center">
        <Link component={RouterLink} to='/roadside-assistance'>24 Hour Road Side Assistance</Link>
      </Box>
    </React.Fragment>
  );
}

export default Navigation;