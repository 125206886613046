import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { bodyShops } from './bodyShops';
import Banner from '../shared/assets/bodyshops-banner.jpg'

const useStyles = makeStyles((theme) => ({
  bannerHeader: {
    backgroundColor: '#fc3',
    fontWeight: 700
  },
  info: {
    paddingBottom: '1rem'
  },
  bodyShops: {
    '@media (max-width: 600px)': {
      paddingTop: '10px',
      '&:not(:last-child)': {
        borderBottom: '1px solid #ccc',
        paddingBottom: '15px'
      }
    }
  }
}));

const BodyshopsPage = () => {
  const classes = useStyles();
  const bodyshopsList = bodyShops.map(el => {
    return (
      <div className={classes.info} key={el.city}>
        <Box pt={1} pb={1} pl={1} className={classes.bannerHeader} fontWeight="500">
          {el.city}
        </Box>
        <Box pl={1} pr={1} mt={1} mb={1}>
          {el.company.map((shop, i) => {
            return (
              <Box className={classes.bodyShops}>
                <Grid container spacing={2} key={i}>
                  <Grid item xs={12} sm={6} md={3}>
                    {shop.name}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    {shop.contactPerson}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    {shop.contactNumber}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    {shop.address}
                  </Grid>
                </Grid>
              </Box>
            )
          })}
        </Box>
      </div>
    )
  })

  return (
    <React.Fragment>
      <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
        Approved Car Bodyshops
      </Typography>
      <Box mb={2}>
        <img style={{ width: '100%' }} alt="Tom Tom" src={Banner} />
      </Box>
      {bodyshopsList}
    </React.Fragment>
  )
}

export default BodyshopsPage;