/* eslint-disable no-plusplus */
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { DealerContext } from '../shared/contexts/DealerContext';
import { VehicleContext } from '../shared/contexts/VehicleContext';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '100px 15px',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 500px)': {
      padding: '0px 0px',
    },
    '@media (min-width:501px) and (max-width: 768px)': {
      padding: '0px 0px',
    },
  },
  innerHolder: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: ' 0 auto',
    padding: '0px 50px',
    width: '80%',
    '@media (max-width: 500px)': {
      width: '100%',
      padding: ' 20px 20px',
    },
    '@media (min-width:501px) and (max-width: 768px)': {
      width: '100%',
      padding: ' 20px 20px',
    },
  },
  card: {
    width: 'calc(100% / 3 - 40px )',
    minWidth: 'calc(100% / 3 - 40px )',
    maxWidth: 'calc(100% / 3 - 40px )',
    cursor: 'pointer',
    borderRadius: '10px',
    margin: '20px ',
    padding: '40px ',
    background: 'white',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    border: '2px solid #eef6f6',
    '&:hover': {
      background: 'white',
      border: ({ websiteColors }) => `2px solid ${websiteColors.accentColor}`,
    },
    '@media (max-width: 425px)': {
      minWidth: '100%',
      margin: '20px 0px',
    },
    '@media (min-width: 426px) and (max-width: 842px)': {
      minWidth: 'calc(100% / 2 - 20px )',
      margin: '  10px ',
    },
  },
  cardTextHolder: {
    flex: 1,
    padding: '0px',
    color: 'white',
    '@media (max-width: 768px)': {},
  },
  cardTextTitleHolder: {
    marginBottom: '10px',
    textAlign: 'center',
  },
  cardTextTitle: {
    fontSize: '19px',
    fontWeight: '500',
  },
  imageHolder: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  cardTextTitleAccent: {
    fontSize: '20px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
  },
  cardTextTitleAccentTop: {
    fontSize: '25px',
    color: 'black',
    margin: '10px 0px',
  },
  cardTextTitleAccentMore: {
    fontSize: '16px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    textTransform: 'uppercase',
    margin: '5px 0px',
  },
  groupCard: {
    width: 'calc(100% / 3 - 40px )',
    minWidth: 'calc(100% / 3 - 40px )',
    cursor: 'pointer',
    borderRadius: '10px',
    margin: '20px ',
    padding: '10px ',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'white',
    border: '2px solid #eef6f6',
    '&:hover': {
      background: 'white',
      border: ({ websiteColors }) => `2px solid ${websiteColors.accentColor}`,
    },
    '@media (max-width: 425px)': {
      width: 'calc(100% / 1 )',
      minWidth: 'calc(100% / 1  )',
      margin: '20px 0px',
    },
    '@media (min-width: 426px) and (max-width: 842px)': {
      width: 'calc(100% / 2 - 20px )',
      margin: '  10px ',
    },
  },
  groupCardLogo: {
    display: 'flex',
    flex: 1,
    margin: '10px 0px',
    aspectRatio: '16/9',
    objectFit: 'contain',
  },
  groupCardImg: {
    display: 'flex',
    flex: 4,
    objectFit: 'contain',
  },
  btnHolder: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: ' 0 auto',
    padding: '0px 50px',
    width: '80%',
    '@media (max-width: 500px)': {
      width: '100%',
      padding: ' 20px 20px',
    },
    '@media (min-width:501px) and (max-width: 768px)': {
      width: '100%',
      padding: ' 20px 20px',
    },
  },
  btn: {
    padding: '10px 35px',
    border: 'none',
    cursor: 'pointer',
    background: '#083246',
    margin: '10px',
    borderRadius: '50px',
    color: 'white',
  },
}));

const ShowRoom = () => {
  const history = useHistory();
  const { newVehiclesList } = useContext(VehicleContext);
  const { globalDealer, websiteColors } = useContext(DealerContext);
  const classes = useStyles({ websiteColors });
  const [vehicles, setVehicles] = useState(newVehiclesList);
  const [vehicleBrands, setVehicleBrands] = useState([]);
  const [activeMake, setActiveMake] = useState('');
  const [activeMakeData, setActiveMakeData] = useState({});

  useEffect(() => {
    if (newVehiclesList) {
      setActiveMake('');
      setVehicles(newVehiclesList);
      if (newVehiclesList.length > 0) {
        const branded = newVehiclesList
          .filter((value, index, self) => index === self.findIndex((t) => t.make === value.make))
          .map((v) => globalDealer?.relatedBrands?.filter((m) => m.id === v.makeId)[0]);
        setVehicleBrands(branded);
        if (branded.length === 1) {
          setActiveMake(branded[0]?.id);
          setActiveMakeData(branded[0]);
        }
      } else {
        setVehicleBrands([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newVehiclesList]);

  const handleRedirect = (data) => {
    history.push(`/show-room/${data?.make}/${data?.ownedModelId}`, data);
  };

  const formatter = new Intl.NumberFormat('af', {
    style: 'currency',
    currency: 'ZAR',
    maximumFractionDigits: 0,
  });

  const handleFilter = (make, data) => {
    setActiveMake(make);
    setActiveMakeData(data);
  };

  return (
    <div className={classes.root}>
      <>
        {activeMake === '' && (
          <div className={classes.innerHolder}>
            {vehicleBrands?.map((v, i) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`group_card_${i}`}
                className={classes.groupCard}
                onClick={() => {
                  handleFilter(v?.id, v);
                }}
              >
                <img alt="" src={v?.heroImage} width="100%" className={classes.groupCardImg} />
                <img alt="" src={v?.logo} width="40%" className={classes.groupCardLogo} />
                {v?.make}
              </div>
            ))}
          </div>
        )}
        {activeMake !== '' && (
          <>
            {vehicleBrands.length !== 1 &&
              <div className={classes.btnHolder}>
                <button
                  type="button"
                  className={classes.btn}
                  onClick={() => {
                    setActiveMake('');
                  }}
                >
                  Back
                </button>
              </div>
            }
            {
              <div
                key={`group_card_${activeMakeData?.id}`}
                style={{ display: 'flex', justifyContent: 'center', width: '100%', maxHeight: '75px' }}
              >
                <img alt="" src={activeMakeData?.logo} width="40%" className={classes.groupCardLogo} />
                {activeMakeData?.make}
              </div>
            }
            <div className={classes.innerHolder}>
              {vehicles
                ?.flat()
                ?.filter((v) => v.makeId === activeMake)
                .map((v, i) => (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={`vehicle_card_${i}`}
                    className={classes.card}
                    onClick={() => {
                      handleRedirect(v);
                    }}
                  >
                    <div className={classes.imageHolder}>
                      <img alt="" src={v?.image} width="100%" />
                    </div>
                    <div className={classes.cardTextHolder}>
                      <div className={classes.cardTextTitleHolder}>
                        <p className={classes.cardTextTitleAccentTop}>{v.title}</p>
                        <h5 className={classes.cardTextTitle}>
                          <strong>
                            <span className={classes.cardTextTitleAccent}>
                              From {formatter.format(v?.price)}
                            </span>
                          </strong>
                        </h5>
                        <p className={classes.cardTextTitleAccentMore}>More Details</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default ShowRoom;
