import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DEALERNAME } from '../shared/Constants'

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0, 6),
  },
  link: {
    color: '#7c7c7c',
    textDecoration: 'none',
    '&:hover': {
      color: '#1f2532',
      textDecoration: 'none'
    }
  }
}));

const Terms = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <div className={classes.content}>
        <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500">
          EXTENDED WARRANTY & SERVICE PLANS
        </Typography>
        <p>
          {DEALERNAME} Motor Group offers a variety of extended warranty & service plans to our customers. These plans are designed to offer you peace-of-mind motoring on new and used vehicles for an extended period of time.
        </p>
        <p>
          There are three products that {DEALERNAME} Motor Group offers; maintenance plans, service plans and extended warranty plans.
        </p>
        <p>
          These all offer a variety of cover based on your needs
        </p>
        <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
          Maintenance Plans
        </Typography>
        <p>Maintenance plans cover the cost of the parts and labour of the serviceable items pertaining to the vehicle’s service schedule (includes items like oil, oil filter, air filter, spark plugs). Includes items like brakes, globes, wiper blades (limitations apply). Excludes wear and tear items like tyres.</p>
        <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
          Service Plans
        </Typography>
        <p>Service plans cover the cost of the parts and labour of the serviceable items pertaining to the vehicle’s service schedule (includes items like oil, oil filter, air filter, spark plugs). Excludes items like brakes, globes, wiper blades. Excludes wear and tear items like tyres.</p>
        <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
          Warranty
        </Typography>
        <p>A warranty covers you in case of mechanical or technical failures of your vehicle and the replacement / repair of these issues. Warranty plans are only valid if you service your vehicle at the approved intervals and only covers the parts and areas specified in the policy. {DEALERNAME} Motor Group’s warranty plans cover vehicles up to 8 years old with less that 350000km. Our warranty products also come with Roadside Assistance.</p>
      </div>
    </Container>
  )
}

export default Terms