import React, { useContext, useState } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import VehicleController from "../usedVehicles/VehicleController";
import { CircularProgress } from "material-ui";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import CarProfileContainer from "../carProfile/UsedCar";
import UsedVehicles from '../usedVehicles/UsedVehicles';
import { useParams, useHistory } from "react-router-dom";
import { UsedVehiclesFilter } from "@red-build/leadcentre";
import { BASE_URI } from "../shared/Constants";
import { DealerContext } from "../shared/contexts/DealerContext";

const useStyles = makeStyles((theme) => ({
  filterLoadingContainer: {
    height: "576px",
    position: "absolute",
    width: "210px",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    zIndex: "10",
    boxShadow: "inset 0 0 0 200px rgb(255 255 255 / 5%)",
    filter: "blur(10px)",
  },
  full: {
    width: "100%",
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0, 6),
  },
  dividerTitle: {
    backgroundColor: "#0089a1",
    width: "33%",
    marginBottom: "30px",
    height: "3px",
    margin: "20px auto",
  },
  carddividerTitle: {
    backgroundColor: "#0089a1",
    width: "100%",
    marginBottom: "10px",
    height: "3px",
    margin: "5px auto",
  },
  bold: {
    fontWeight: 700,
  },
  lighter: {
    fontWeight: "lighter",
  },
  flex: {
    display: "flex",
    flexDirection: "row!important",
    justifyContent: "space-between",
  },
  clear: {
    padding: "0px!important",
  },
  price: {
    fontSize: "1.5rem",
    "&>span": {
      fontWeight: 400,
    },
  },
  pricepm: {
    fontSize: "0.8rem",
    "&>span": {
      fontWeight: 400,
    },
  },
  title: {
    fontSize: "1.0rem",
    "&>span": {
      fontWeight: 400,
    },
  },
  vehicleContainer: {
    "& h5, & p": {
      color: "#7c7c7c",
    },
    "&:hover img, &:hover p, &:hover h5": {
      color: "#1f2532",
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  btn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginTop: "2%",
    width: "45%",
  },
  btn1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginTop: "2%",
    width: "45%",
    background: "white",
    border: "1px solid black",
  },
  btn2: {
    minWidth: "100%",
  },
  image: {
    "& > div > div": {
      backgroundColor: "#ffffff !important",
    },
  },
  carsLoadContainer: {
    display: "flex !important",
    justifyContent: "center",
  },
  CarsLoad: {
    display: 'inherit !important',
    margin: 'auto',
    marginTop: '14%'
  },
  flexTop: {
    display: "flex",
    flexDirection: "column!important",
    justifyContent: "space-between",
  },
  center: {
    display: "flex",
    flexDirection: "column!important",
    margin: '0 auto',
  },
  backBtn: {
    borderRadius: '40px',
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginTop: "2%",
    background: '#0089a1',
    border: 'none',
    padding: "10px 40px",
    margin: "20px 23px",
    color: 'white',
    cursor: "pointer"
  },
  TopTi: {
    fontSize: '40px',
    fontWeight: "unset",
    marginBottom: "50px"
  },
  accent: {
    color: '#0089a1'
  },
  filterHolder: {
    marginBottom: "50px",
    "@media (min-width:0px) and (max-width:425px)": {
      margin: "10px",
    },
  }
}));

const UsedCarUsedVehiclesPage = () => {
  const history = useHistory();
  const classes = useStyles();
  const [vehiclesList, setVehiclesList] = useState([]);
  const [activeVehicle, setActiveVehicle] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [searchLoading, setSearchLoading] = useState(false);
  const { loading, vehicles } = VehicleController();
  let { stockId } = useParams();
  const { websiteColors, globalDealer } = useContext(DealerContext)

  const handleBack = () => {
    history.push(`/used-vehicles`)
    setActiveVehicle();
  }

  const onSelectVehicle = (vehicle) => {
    history.push(`/used-vehicles/${vehicle?.stockId}`)
    setActiveVehicle(vehicle);
  }

  const defaultColors = {
    primaryColor: websiteColors.secondaryColor,
    secondaryColor: websiteColors.primaryColor,
    labelColor: 'black',
  };

  let multiSelect = true

  return (
    <MuiThemeProvider>
      <Container maxWidth="lg" className={classes.content}>
        {!stockId && <Grid className={classes.flexTop}>
          <Typography
            gutterBottom
            variant="h1"
            align="center"
            color="textPrimary"
            fontWeight="500"
            className={classes.TopTi}
          >
            Discover our large range of <span className={classes.accent}>used</span> vehicles
          </Typography>
          <div className={classes.filterHolder} >
            <UsedVehiclesFilter
              pageSize={20}
              dealerId={globalDealer.dealerId}
              motorgroupId={7}
              orientation='row'
              transmissionHide
              instalmentsHide
              updateFilteredVehicles={setVehiclesList}
              vehicles={vehicles}
              base_uri={BASE_URI}
              websiteColors={defaultColors}
              multiSelect={multiSelect}
            />
          </div>
        </Grid>
        }
        {stockId && <button className={classes.backBtn} onClick={() => { handleBack() }}>Back</button>}
        <div md={10} xs={12} className={classes.flex}>
          {loading || searchLoading ? (
            <div
              className={classes.carsLoadContainer}
              md={12}
            >
              <CircularProgress className={classes.CarsLoad} size={200} />
            </div>
          ) : (
            <>
              <div>
                {stockId ? <CarProfileContainer searchLoading={searchLoading} activeVehicle={activeVehicle} />
                  : <UsedVehicles vehicles={vehiclesList.flat()} onSelectView={onSelectVehicle} />}
              </div>
            </>
          )}
        </div>
      </Container>
    </MuiThemeProvider>
  );
};

export default UsedCarUsedVehiclesPage;
