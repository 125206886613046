/* eslint-disable eqeqeq */
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { useHistory } from "react-router-dom";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { FinanceContext } from '../shared/contexts/FinanceContext';

const useStyles = makeStyles((theme) => ({
    text: {
       textAlign:'center',
      },
      success: {
        color: 'black',
        fontSize: '20px',
        display:'flex',
        alignItems: 'center',
        flexDirection:'column',
        background:'#e30011',
    },
    successHolder: {
        padding: theme.spacing(2, 0, 2),
        color: 'black',
        fontSize: '20px',
        display:'flex',
        alignItems: 'center',
        flexDirection:'column',
        background:'white',
    },
    icon: {
        fontSize: '3rem',
    },
    btn: {
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
        margin: "0% 1%",
        color: 'white',
        background:'#e30011',
        padding: "2% 5%",
        textTransform: 'uppercase',
        whiteSpace:'nowrap',
        '&:hover': {
           background:'#860000',
        }
      },
      flexbtn: {
        display: "flex",
        flexDirection: "row!important",
        justifyContent: "space-around",
      },
}));
  
export default function Success() {
    const history = useHistory();
    const classes = useStyles();

    let { globalLeadType, setGlobalLeadType } = useContext(FinanceContext);
    const goToPage = (leadType, page) => {
        setGlobalLeadType(leadType);
        history.push(page);
    }

    return <div className={classes.successHolder}>
     <div ><MailOutlineIcon className={classes.icon} /></div>
        <div >Form Submitted Successfully</div>
        <br />
        <br />
        {globalLeadType == 8 &&
        <div>
        <Box mb={3} className={classes.flexbtn}>   
            <Link className={classes.btn} onClick={() => goToPage(5, '/contact/')} linkto={'service'}>Service</Link>   
            <Link className={classes.btn} onClick={() => goToPage(9, '/tradein')} linkto={'tradein'}>Valuation</Link>    
            <Link className={classes.btn} onClick={() => goToPage(1, '/our-vehicles')} linkto={'our-vehicles'}>FINANCE</Link>           
        </Box>
        <Box mb={2} className={classes.flexbtn}>
            <Link className={classes.btn} onClick={() => { window.location.href = 'https://wa.me/27659028492?text=Hi'; } }>WHATSAPP</Link>    
            <Link className={classes.btn} onClick={() => goToPage(11, '/our-vehicles')} linkto={'our-vehicles'}>CONFIGURE</Link>           
        </Box>
        </div>}
        {globalLeadType == 9 &&
        <div>
        <Box mb={3} className={classes.flexbtn}>   
            <Link className={classes.btn} onClick={() => goToPage(8, '/our-vehicles')} linkto={'our-vehicles'}>TEST Drive</Link>
            <Link className={classes.btn} onClick={() => goToPage(5, '/contact/a')} linkto={'service'}>Service</Link>   
    
            <Link className={classes.btn} onClick={() => goToPage(1, '/our-vehicles')} linkto={'our-vehicles'}>FINANCE</Link>           
        </Box>
        <Box mb={2} className={classes.flexbtn}>
            <Link className={classes.btn} onClick={() => { window.location.href = 'https://wa.me/27659028492?text=Hi'; } }>WHATSAPP</Link>    
            <Link className={classes.btn} onClick={() => goToPage(11, '/our-vehicles')} linkto={'our-vehicles'}>CONFIGURE</Link>           
        </Box>
        </div>}
        {globalLeadType == 5 &&
        <div>
        <Box mb={3} className={classes.flexbtn}>   
            <Link className={classes.btn} onClick={() => goToPage(8, '/our-vehicles')} linkto={'our-vehicles'}>TEST Drive</Link>
   
            <Link className={classes.btn} onClick={() => goToPage(9, '/tradein')} linkto={'tradein'}>Valuation</Link>    
            <Link className={classes.btn} onClick={() => goToPage(1, '/our-vehicles')} linkto={'our-vehicles'}>FINANCE</Link>           
        </Box>
        <Box mb={2} className={classes.flexbtn}>
            <Link className={classes.btn} onClick={() => { window.location.href = 'https://wa.me/27659028492?text=Hi'; } }>WHATSAPP</Link>    
            <Link className={classes.btn} onClick={() => goToPage(11, '/our-vehicles')} linkto={'our-vehicles'}>CONFIGURE</Link>           
        </Box>
        </div>}
        {globalLeadType == 3 &&
        <div>
        <Box mb={3} className={classes.flexbtn}>   
            <Link className={classes.btn} onClick={() => goToPage(8, '/our-vehicles')} linkto={'our-vehicles'}>TEST Drive</Link>
            <Link className={classes.btn} onClick={() => goToPage(5, '/contact/')} linkto={'service'}>Service</Link>   
            <Link className={classes.btn} onClick={() => goToPage(9, '/tradein')} linkto={'tradein'}>Valuation</Link>    
            <Link className={classes.btn} onClick={() => goToPage(1, '/our-vehicles')} linkto={'our-vehicles'}>FINANCE</Link>           
        </Box>
        <Box mb={2} className={classes.flexbtn}>
        
            <Link className={classes.btn} onClick={() => { window.location.href = 'https://wa.me/27659028492?text=Hi'; } }>WHATSAPP</Link>    
            <Link className={classes.btn} onClick={() => goToPage(11, '/our-vehicles')} linkto={'our-vehicles'}>CONFIGURE</Link>           
        </Box>
        </div>}
        {globalLeadType == 22 &&
        <div>
        <Box mb={3} className={classes.flexbtn}>   
            <Link className={classes.btn} onClick={() => goToPage(8, '/our-vehicles')} linkto={'our-vehicles'}>TEST Drive</Link>
            <Link className={classes.btn} onClick={() => goToPage(5, '/contact/a')} linkto={'service'}>Service</Link>   
            <Link className={classes.btn} onClick={() => goToPage(9, '/tradein')} linkto={'tradein'}>Valuation</Link>    
            <Link className={classes.btn} onClick={() => goToPage(1, '/our-vehicles')} linkto={'our-vehicles'}>FINANCE</Link>           
        </Box>
        <Box mb={2} className={classes.flexbtn}>
          
            <Link className={classes.btn} onClick={() => { window.location.href = 'https://wa.me/27659028492?text=Hi'; } }>WHATSAPP</Link>    
            <Link className={classes.btn} onClick={() => goToPage(11, '/our-vehicles')} linkto={'our-vehicles'}>CONFIGURE</Link>           
        </Box>
        </div>}
        {globalLeadType == 1 &&
        <div>
        <Box mb={3} className={classes.flexbtn}>   
            <Link className={classes.btn} onClick={() => goToPage(8, '/our-vehicles')} linkto={'our-vehicles'}>TEST Drive</Link>
            <Link className={classes.btn} onClick={() => goToPage(5, '/contact/a')} linkto={'service'}>Service</Link>   
            <Link className={classes.btn} onClick={() => goToPage(9, '/tradein')} linkto={'tradein'}>Valuation</Link>    
                     
        </Box>
        <Box mb={2} className={classes.flexbtn}>
            <Link className={classes.btn} onClick={() => { window.location.href = 'https://wa.me/27659028492?text=Hi'; } }>WHATSAPP</Link>    
            <Link className={classes.btn} onClick={() => goToPage(11, '/our-vehicles')} linkto={'our-vehicles'}>CONFIGURE</Link>           
        </Box>
        </div>}
        {globalLeadType == 11 &&
        <div>
        <Box mb={3} className={classes.flexbtn}>   
            <Link className={classes.btn} onClick={() => goToPage(8, '/our-vehicles')} linkto={'our-vehicles'}>TEST Drive</Link>
            <Link className={classes.btn} onClick={() => goToPage(5, '/contact/a')} linkto={'service'}>Service</Link>   
            <Link className={classes.btn} onClick={() => goToPage(9, '/tradein')} linkto={'tradein'}>Valuation</Link>    
            <Link className={classes.btn} onClick={() => goToPage(1, '/our-vehicles')} linkto={'our-vehicles'}>FINANCE</Link>           
        </Box>
        <Box mb={2} className={classes.flexbtn}>
            <Link className={classes.btn} onClick={() => { window.location.href = 'https://wa.me/27659028492?text=Hi'; } }>WHATSAPP</Link>    
                    
        </Box>
        </div>}
     </div>
}