import React, { useContext } from 'react';
import './App.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import DashboardContainer from './components/MainContainer'
import RenaultLifeWebRegular from './fonts/suzukiproregular-webfont.woff'
import RenaultLifeWebBold from './fonts/suzukiproheadline-webfont.woff'
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import ScrollToTop from './components/shared/ScrollToTop'
import Cookie from './components/shared/CookiePopUp'
import { MetaTagsProvider } from '@red-build/leadcentre';
import { VehicleProvider } from './components/shared/contexts/VehicleContext';
import { DealerContext } from './components/shared/contexts/DealerContext';

const renaultRegualar = {
  fontFamily: 'Renault',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Renault'),
    local('Renault-Regular'),
    url(${RenaultLifeWebRegular}) format('woff')
  `
}

const renaultBold = {
  fontFamily: 'Renault',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('Renault'),
    local('Renault-Bold'),
    url(${RenaultLifeWebBold}) format('woff')
  `
}

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Renault',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiTextField: {
      root: {
        marginBottom: '1rem'
      }
    },
    MuiTypography: {
      h4: {
        fontWeight: 700
      },
      h5: {
        textTransform: 'capitalize',
        fontWeight: 700
      },
      h6: {
        fontWeight: 700
      },
      subtitle2: {
        fontSize: '1rem'
      }
    },
    MuiDialogActions: {
      root: {
        padding: '1.5rem 1rem'
      }
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#0089a1',
        color: '#ffffff',
        borderRadius: '0px',
        '&:hover': {
          backgroundColor: '#0089a1',
          opacity: 0.8
        }
      },
      contained: {
        borderRadius: '0px'
      }
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [renaultRegualar, renaultBold],
        body: {
          backgroundColor: '#ffffff',
          fontSize: '100%'
        }
      }
    }
  }
});

function App() {
  const { globalDealer } = useContext(DealerContext);

  return (
    <Router>
      <ScrollToTop />
      <Cookie />
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3} anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}>
          <CssBaseline />
          <VehicleProvider>
            <MetaTagsProvider>
              {globalDealer && <DashboardContainer />}
            </MetaTagsProvider>
          </VehicleProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
