/* eslint-disable eqeqeq */
import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import { BASE_URI, MASTER_DEALER_NAME } from "./Constants"
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import Success from "../shared/Success";
import { DealerContext } from '../shared/contexts/DealerContext'
import { ContactUs } from "@red-build/leadcentre";

const useStyles = makeStyles((theme) => ({
  stepPadding: {
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    marginTop: "2rem",
  },
  stepPaddingBelow: {
    marginRight: "0.5rem",
    marginTop: "0.5rem",
  },
  stepPaddingButton: {
    marginTop: "0.5rem",
  },
  stepPaddingTitle: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  card: {
    width: "100%"
  },
  flex: {
    display: "flex"
  },
  button: {
    width: "100%",
  },
  backButton: {
    marginTop: "2rem",
  },
  image: {
    minWidth: "100%",
    height: 550,
  },
  uppercase: {
    textTransform: 'uppercase'
  },
}));

export default function ContactContainer(props) {
  const leadType = props.leadTypeId;
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [success, setSuccess] = useState(false)
  const { dealerList, globalDealer, websiteColors } = useContext(DealerContext)
  return <Container>
    {(success) && <Success />}

    {(!success) &&
      <React.Fragment>
        <MuiThemeProvider>
          <Grid container direction="row">
            <Grid item xs={12}>
              <Card className={classes.content} elevation={0}>

              <ContactUs dealerId={globalDealer?.dealerId} base_uri={BASE_URI} leadTypeId={leadType || 3} dealerName={globalDealer?.name == MASTER_DEALER_NAME ? "" : globalDealer?.name} dealers={dealerList} dealerType="multi_dealer" websiteColors={websiteColors} note={true} />
              </Card>
            </Grid>
          </Grid>
        </MuiThemeProvider>
      </React.Fragment>
    }
  </Container>
}