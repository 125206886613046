import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { BASE_URI, MOTORGROUPID } from '../shared/Constants'

export default function VehicleController() {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [vehicles, setVehicles] = useState([])
    const [total, setTotal] = useState(false)
    const { dealerId } = useParams(-1);

    useEffect(() => {

        let cancel
        setLoading(true)
        setError(false)
        setVehicles([])

        const params = {
            pageNumber: 1,
            motorGroupId: MOTORGROUPID,
            dealerId: dealerId,
            pageSize: 1000
        }

        axios({
            method: 'GET',
            url: `${BASE_URI}/stock`,
            params,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {

            setVehicles(response.data?.list)
            setTotal(response.data?.list.length > 0)
            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) return
            setLoading(false)
        })
        return () => cancel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { loading, error, vehicles, total }
}