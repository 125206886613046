/* eslint-disable eqeqeq */
import React, { useState, useRef, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import * as tmpDeals from './tmpDeals'
import PaymentDetails from '../shared/PaymentDetails'
import { ImageContainer } from '../shared/ImageContainer'
import Carousel from '../homePage/CarouselSingle'
import { SpecificationsComponent } from '../shared/SpecificationsComponent'
import { useParams, useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BASE_URI } from '../shared/Constants';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import { CurrencyValue } from '../shared/TextMask';
import { FinanceContext } from '../shared/contexts/FinanceContext';
import CheckIcon from '@material-ui/icons/Check';
import ApplySteps from '../shared/ApplySteps';
import TextField from "@material-ui/core/TextField";
import { Formik, Form } from "formik";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0, 6),
  },
  largeButton: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    width: '100%',
    '& h6': {
      fontSize: '28.8px'
    }
  },
  buttonIconLg: {
    fontSize: '5rem',
    color: '#0089a1'
  },
  sectionPadding: {
    margin: '3rem 0'
  },
  paragraphPadding: {
    padding: '1.5rem 3rem !important'
  },
  heading: {
    borderBottom: '5px solid #0089a1',
    textTransform: 'capitalize',
    paddingBottom: '.5rem',
    fontWeight: '700',
    display: 'inline-block'
  },
  yellowBorder: {
    borderBottom: '5px solid #0089a1',
  },
  secondaryHeading: {
    textTransform: 'uppercase',
    paddingBottom: '1rem',
    fontWeight: '400'
  },
  slideMargin: {
    width: 'calc(100% - 20px)',
    height: 'calc(100% - 20px)',
    left: '10px',
    top: '10px',
  },
  topHeader: {
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '2.5rem'
  },
  yellow: {
    color: '#0089a1'
  },
  specs: {
    '& .specs': {
      borderBottom: '0 !important',
      marginTop: '-25px'
    },
  },
  formControl: {
    minWidth: 400,
    '@media (max-width: 450px)': {
      minWidth: '100%'
    }
  },
  headerLine: {
    height: '1.5px',
    width: '50%',
    backgroundColor: '#0089a1',
    padding: '0 !important'
  },
  smallHeading: {
    fontSize: '1.3rem'
  },
  stepPaddingColours: {
    marginLeft: "1em",
    marginRight: "1em",
    marginTop: "0.5em",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  colorsContainer: {
    display: 'flex',
    overflow: 'auto',
    '& > div:first-child': {
      marginLeft: 0
    }
  },
  colourName: {
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    marginTop: '0.6rem'
  },
  colourPrice: {
    fontWeight: 700
  },
  stepOneButtons: {
    display: 'flex',
    '@media (max-width: 500px)': {
      flexDirection: 'column',
      alignItems: 'center',
      '& > a': {
        marginLeft: '0 !important',
        marginRight: '0 !important'
      }
    }
  },
  topDetailSection: {
    '@media screen and (max-width: 959px)': {
      display: 'flex',
      flexDirection: 'column',
      '& > div:first-child': {
        order: 2,
        marginTop: '2rem'
      },
      '& > div:nth-child(2)': {
        order: 3
      },
      '& > div:nth-child(3)': {
        order: 4
      },
      '& > div:nth-child(4)': {
        marginTop: '-1.5rem'
      }
    }
  }
}));



export default function CarProfileContainer() {
  const classes = useStyles();
  let { carId, dealId } = useParams();
  // const [Id, setId] = useState(carId)
  let tmpCarId = (carId).toLowerCase().replace(/ /gi, "%");
  const history = useHistory();

  let vehicleProfile;
  let vehicleColours = [];

  switch (true) {
    case tmpCarId.indexOf("sedona") !== -1 || tmpCarId.indexOf("grand-sedona") !== -1:
      vehicleProfile = tmpDeals.tmpDealgrandSedona;
      break;
    case tmpCarId.indexOf("k2500") !== -1:
      vehicleProfile = tmpDeals.tmpDealk2500;
      break;
    case tmpCarId.indexOf("runner") !== -1:
      vehicleProfile = tmpDeals.tmpDealpicantoRunner;
      break;
    case tmpCarId.indexOf("picanto") !== -1:
      vehicleProfile = tmpDeals.tmpDealpicanto;
      break;
    case tmpCarId.indexOf("rio") !== -1:
      vehicleProfile = tmpDeals.tmpDealrio;
      break;
    case tmpCarId.indexOf("seltos") !== -1:
      vehicleProfile = tmpDeals.tmpDealseltos;
      break;
    case tmpCarId.indexOf("sorento") !== -1:
      vehicleProfile = tmpDeals.tmpDealsorento;
      break;
    case tmpCarId.indexOf("sonet") !== -1:
      vehicleProfile = tmpDeals.tmpDealsonet;
      break;
    case tmpCarId.indexOf("sportage") !== -1:
      vehicleProfile = tmpDeals.tmpDealsportage;
      break;
    case tmpCarId.indexOf("Pegas") !== -1:
      vehicleProfile = tmpDeals.tmpDealPegas;
      break;
    case tmpCarId.indexOf("K2700") !== -1:
      vehicleProfile = tmpDeals.tmpDealK2700;
      break;
    default:
      vehicleProfile = []
  }

  const [carProfile, setCarProfile] = useState(vehicleProfile);
  const [colors, setColours] = useState(vehicleColours);
  const [loading, setLoading] = useState(true);
  const mountedRef = useRef(true)
  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line no-unused-vars
  const [financeOptions, setFinanceOptions] = useState([])
  const [financeDetails, setFinanceDetails] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [headline, setHeadline] = useState('')
  const [modelData, setModelData] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [modelInfo, setModelInfo] = useState(null);
  const [modelId, setModelId] = React.useState(0);
  const [colorData, setColorData] = React.useState([]);
  const [selectedColor, setSelectedColor] = React.useState();
  const [colorId, setColorId] = React.useState(0);

  let { setGlobalFinance, setGlobalVehicle, setGlobalColor, setGlobalVariantId, setGlobalModel } = useContext(FinanceContext);

  const values =
  {
    model: "",
    modelId: "",

  };

  const handleModelChange = async (e, value, setFieldValue) => {
    setFieldValue("model", e.target.value);
    setFieldValue("modelId", value.props.id);
    setModelId(parseInt(value.props.id));

    var model = modelData.filter(itm => itm.id == value.props.id)[0];
    var tmpFinanceDetails = { ...financeDetails, originalPrice: model.priceRange, purchasePrice: model.priceRange };
    let monthly = calculateMonthly(tmpFinanceDetails);
    tmpFinanceDetails = { ...tmpFinanceDetails, monthlyInstallment: monthly };

    setFinanceDetails(tmpFinanceDetails);
    setGlobalFinance(tmpFinanceDetails);
    setGlobalModel(model);
  };

  const calculateMonthly = (data) => {
    var deposit = data.purchasePrice * (data.deposit / 100);
    var totalPrice = data.purchasePrice;
    var initFee = 0;
    var principal = totalPrice - deposit + initFee;
    var balloonPerc = data.balloonPayment;
    var balloonAmt = totalPrice * balloonPerc / 100;
    var interestRate = data.linkedInterestRate;
    var interestPM = interestRate / 100 / 12;
    var repaymentPeriod = data.term;
    var days = 1;
    var v = 1 / (1 + interestPM);
    var d = 1 - v;
    var y = Math.pow(v, repaymentPeriod - 1);
    var comp = (1 - y) / d;
    var fp = principal * (Math.pow(1 + interestPM, (days / (365 / 12).toFixed())));
    var monthly = 0;

    if (parseInt(balloonAmt) > 0) {
      comp = (1 - (Math.pow(v, repaymentPeriod - 1))) / d;
      monthly = (fp - (balloonAmt * Math.pow(v, repaymentPeriod - 1))) / comp;
    }
    else {
      comp = (1 - (Math.pow(v, repaymentPeriod))) / d;
      monthly = fp / comp;
    }

    return round(monthly) + initFee;
  }

  function round(x) {
    return Math.round(x * 100) / 100;
  }

  useEffect(() => {
    if (colorId == 0) {
      return
    }

    var tmpFinanceDetails;
    var color = colorData.filter(itm => itm.id == colorId)[0];

    if (color.price > 0) {
      tmpFinanceDetails = { ...financeDetails, purchasePrice: color.price };
    }
    else {
      tmpFinanceDetails = { ...financeDetails, purchasePrice: financeDetails.originalPrice };
    }

    let monthly = calculateMonthly(tmpFinanceDetails);
    tmpFinanceDetails = { ...tmpFinanceDetails, monthlyInstallment: monthly };

    setFinanceDetails(tmpFinanceDetails);
    setGlobalFinance(tmpFinanceDetails);
    setGlobalColor(color);
    setSelectedColor(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorId]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    setLoading(true)
    const source = CancelToken.source();

    axios.get(`${BASE_URI}/dealervariants?dealerModelId=${vehicleProfile.financeOptions[0].modelId}`, {
      cancelToken: source.token
    }).then(result => {
      let data = result.data.list
      setModelData(data);
      setLoading(false)
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carId]);

  useEffect(() => {
    if (!modelId || modelId == 0) {
      return;
    }
    setGlobalVariantId(modelId);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    axios.get(`${BASE_URI}/colours/exteriors?dealerVariantId=${modelId}`, {
      cancelToken: source.token
    }).then(result => {
      let data = result.data.list
      setColorData(data);
      setColours(data.map(itm => { return { img: itm.image }; }));
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId])

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    setLoading(true)
    const source = CancelToken.source();
    const getOffer = async () => {
      try {
        setLoading(false)
        const result = await axios.get(`${BASE_URI}/Offers/${dealId}`, {
          cancelToken: source.token
        })
        let data = result.data

        setHeadline(result.data.headline)
        let tmpFinanceOptions = []

        tmpFinanceOptions.push({
          variant: result.data.variant,
          variantId: result.data.variantId,
          monthlyInstallment: result.data.monthlyInstallment,
          id: result.data.id,
          model: result.data.model
        })

        setFinanceOptions(tmpFinanceOptions)

        let tmpFinanceDetails = {
          purchasePrice: data.price,
          monthlyInstallment: data.monthlyInstallment,
          term: data.term,
          linkedInterestRate: data.rate,
          deposit: data.deposit,
          balloonPayment: data.balloonPayment,
          termsAndConditions: data.termsAndConditions,
          // image: result.data.image,
          specs: [
            {
              title: '8.1 - 8.3 l/100km',
              feature: 'Average Fuel Consumption'
            },
            {
              title: '60 litres',
              feature: 'Fuel tank'
            },
            {
              title: 'EBD',
              feature: 'Electronic Brakeforce Distribution'
            },
            {
              title: 'ABS',
              feature: 'Anti-lock Braking System'
            }
          ],
        }
        setFinanceDetails(tmpFinanceDetails)
        setGlobalFinance(tmpFinanceDetails)
      } catch (error) {
        setLoading(false)
        enqueueSnackbar("Unable to get offer details", { variant: 'error' });
      }
    }

    if (dealId) {
      setCarProfile(vehicleProfile)
      setGlobalVehicle(vehicleProfile)
      setColours(vehicleColours)
      getOffer().then(() => {
        window.scrollTo(0, 0);
        setLoading(false)
        if (!mountedRef.current) return null
      })
    } else {
      setHeadline(vehicleProfile.headline)
      setCarProfile(vehicleProfile)
      setGlobalVehicle(vehicleProfile)
      setFinanceDetails(vehicleProfile.dealDetails)
      setGlobalFinance(vehicleProfile.dealDetails)
      setFinanceOptions(vehicleProfile.financeOptions)
      setColours(vehicleColours)
      setLoading(false)
    }

    return () => {
      mountedRef.current = false
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carId, dealId])

  const padZero = (str, len) => {
    len = len || 2;
    let zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  }

  const invertColor = (hex) => {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    // invert color components
    let r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
      g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
      b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return '#' + padZero(r) + padZero(g) + padZero(b);
  }

  const stepsState = {
    one: {
      active: true,
      completed: false
    },
    two: {
      active: true,
      completed: false
    },
    three: {
      active: true,
      completed: false
    }
  }

  const getMainImage = () => {
    var image = (carProfile.infoMedia && carProfile.infoMedia.large) ? carProfile.infoMedia.large : '';
    if (selectedColor) {
      image = selectedColor.image;
    }
    return image;
  }

  return (
    <React.Fragment>
      <main>
        <Container maxWidth="lg">
          {
            loading &&
            <Grid item container justifyContent='center' justify='center' alignContent='center' xs={12}>
              <CircularProgress size={80} />
            </Grid>
          }
          {
            !loading &&
            <div className={classes.background}>
              <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500">
                <Box mb={5} className={classes.topHeader}>
                  <span className={classes.yellow}>Step 1</span> let's <span className={classes.yellow}>configure</span> your car
                </Box>
              </Typography>

              <Grid container spacing={4} style={{ marginBottom: '1rem' }}>
                <Grid item xs={12} md={6}>
                  <Box style={{ padding: ' 0 0.6rem' }}>
                    {selectedColor ? <ImageContainer aspectRatio={(16 / 10)} src={getMainImage()} alt='MainImage' /> : <ImageContainer aspectRatio={(16 / 6)} src={getMainImage()} alt='MainImage' />}
                  </Box>
                  {colors.length > 0 &&
                    <Box style={{ marginBottom: '1rem' }}>
                      {

                        <Carousel naturalSlideWidth={100}
                          naturalSlideHeight={60}
                          totalSlides={colors.length}
                          visibleSlides={4}
                          isPlaying={true}
                          infinite
                          innerClass={classes.slideMargin}
                          slides={colors}
                        />
                      }
                    </Box>
                  }
                  <Box className={classes.specs}>
                    <PaymentDetails displayPrice={false} displayDetails={false} displaySpecs details={modelInfo ?? financeDetails ?? carProfile.dealDetails} />
                  </Box>
                  <Box>
                    <Card style={{ marginTop: '1.5rem', border: '1px solid #ccc', padding: '1rem' }}>
                      <Typography variant="h4" align="center">Apply online in <span style={{ fontSize: '2rem' }} className={classes.yellow}>3</span> easy steps</Typography>
                      <ApplySteps disabledStates={stepsState} />
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Formik
                    initialValues={values}
                    isInitialValid={false}
                    onSubmit={(values, actions) => {
                      setTimeout(() => {

                        actions.setSubmitting(true);
                      }, 100);
                    }}
                  >
                    {(props) => {
                      const { values, touched, errors, handleBlur, setFieldValue } = props;

                      return (
                        <Form>
                          <Typography className={classes.smallHeading}>
                            {carProfile.model}
                          </Typography>
                          <Typography variant="h4" color="textPrimary">
                            <strong><CurrencyValue value={financeDetails.purchasePrice} /></strong> or <strong><CurrencyValue value={financeDetails.monthlyInstallment} />pm</strong>
                          </Typography>
                          <div className={classes.headerLine}></div>

                          <Box style={{ marginTop: 24 }}>
                            <TextField
                              fullWidth
                              id="model"
                              variant="outlined"
                              select
                              required
                              label="Select a model"
                              value={values.model}
                              helperText={errors.model && touched.model ? errors.model : ""}
                              error={errors.model && touched.model}
                              onBlur={handleBlur("make")}
                              onChange={(e, child) => {
                                handleModelChange(e, child, setFieldValue);
                              }}>
                              {modelData && modelData.map((option) => (
                                <MenuItem key={option.id} id={option.id} value={option.title}>
                                  {option.title} (From <CurrencyValue value={option.priceRange} />)
                                </MenuItem>
                              ))}
                            </TextField>
                          </Box>
                          {colorData && colorData.length > 0 &&
                            <Box style={{ marginTop: 24 }}>
                              <Typography className={classes.smallHeading}>
                                Select Colour
                              </Typography>
                              <Box className={classes.colorsContainer}>
                                {colorData.map(color => {
                                  return <Box className={classes.stepPaddingColours} key={`color-${color.id}`}>
                                    <Button
                                      style={{
                                        backgroundColor: `${color.colour}`,
                                        height: '60px',
                                        width: '60px',
                                        minWidth: '60px',
                                        borderRadius: '50%',
                                      }}
                                      onClick={(v) => {
                                        setColorId(color.id);
                                      }}
                                      variant={"contained"}
                                    >
                                      {colorId === color.id &&
                                        <CheckIcon style={{ color: invertColor(`${color.colour}`) }} />
                                      }
                                    </Button>
                                    <Typography className={classes.colourName}>{color.title}</Typography>
                                    <Typography className={classes.colourPrice}>
                                      {/* <CurrencyValue value={colour.price} /> */}
                                    </Typography>
                                  </Box>
                                })
                                }
                              </Box>
                            </Box>
                          }
                          <PaymentDetails displayPrice={false} details={financeDetails ? financeDetails : carProfile.dealDetails} />
                          <Box mb={3} mt={3} className={classes.stepOneButtons} style={{ width: 'initial', display: 'flex' }}>
                            <Link style={{ margin: '0 10px 10px 0' }}>
                              <Button variant="contained" gutterBottom onClick={() => { history.push(`/apply-for-finance`) }} color="primary" disabled={colorId <= 0}>
                                Pre-Qualify Now
                              </Button>
                            </Link>
                            <Link style={{ margin: '0 10px' }}>
                              <Button variant="contained" gutterBottom onClick={() => { history.push(`/apply-for-finance`) }} color="primary" disabled={colorId <= 0}>
                                Request Call back
                              </Button>
                            </Link>
                          </Box>
                          <Box>
                            <Typography style={{ marginTop: '.5rem' }} variant="caption" color="textSecondary">
                              {financeDetails.termsAndConditions}
                            </Typography>
                          </Box>
                        </Form>
                      );
                    }}
                  </Formik>
                </Grid>
              </Grid>
              <Grid container className={classes.sectionPadding}>
                <Grid item xs={12}>
                  <ImageContainer aspectRatio={(16 / 6)} src={(carProfile.infoMedia && carProfile.infoMedia.large) ? carProfile.infoMedia.large : ''} alt='MainImage' />
                </Grid>
              </Grid>
              <Grid container spacing={4} style={{ marginTop: '3rem', marginBottom: '3rem' }}>
                <Grid item container alignContent='center' justify='center' xs={12}>
                  <Typography className={classes.heading} align='center'
                    id="discover"
                    gutterBottom variant="h4" color="textPrimary" fontWeight="500">
                    {carProfile.carInfo.mainTitle}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.paragraphPadding}>
                  <Typography>
                    {carProfile.carInfo.introContent}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <ImageContainer height='100%' src={carProfile.infoMedia.small} alt='MainImage' />
                </Grid>
                <Grid item xs={12} md={8} container alignContent='center' justify='center'>
                  <Typography className={classes.heading} align='center' gutterBottom variant="h4" color="textPrimary" fontWeight="500">
                    {carProfile.carInfo.subTitle1}
                  </Typography>
                  <Typography className={classes.paragraphPadding}>
                    {carProfile.carInfo.subContent1}
                  </Typography>
                  <div className={classes.largeButton} style={{ width: 'initial' }}>
                    <Box mb={3} mt={3}>
                      <Button variant="contained" gutterBottom onClick={() => {
                        window.open(modelInfo?.brochures[0]?.document?.filePath ?? vehicleProfile.brochure, '_blank')
                      }} color="primary">
                        Download Brochure
                      </Button>
                    </Box>
                    <Link href='/apply-for-finance' style={{ width: '100%' }}>
                      <Button variant="contained" color="primary" style={{ width: '100%' }}>
                        Apply for Finance
                      </Button>
                    </Link>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box mb={3}>
                    <ImageContainer src={carProfile.infoMedia.medium1} alt='MainImage' />
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <Typography className={classes.heading} align='center' gutterBottom variant="h4" color="textPrimary" fontWeight="500">
                      {carProfile.carInfo.subTitle2}
                    </Typography>
                  </Box>
                  <Typography className={classes.paragraphPadding}>
                    {carProfile.carInfo.subContent2}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} >
                  <Box mb={3}>
                    <ImageContainer src={carProfile.infoMedia.medium2} alt='MainImage' />
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <Typography className={classes.heading} align='center' gutterBottom variant="h4" color="textPrimary" fontWeight="500">
                      {carProfile.carInfo.subTitle3}
                    </Typography>
                  </Box>
                  <Typography className={classes.paragraphPadding}>
                    {carProfile.carInfo.subContent3}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignContent='center' justify='center' className={classes.sectionPadding}>
                <Grid item xs={8} className={classes.yellowBorder} md={4}>
                </Grid>
              </Grid>
              {false && <>
                <Grid container className={classes.sectionPadding}>
                  <Grid item xs={12}>
                    <Box mb={3}>
                      <Typography className={classes.secondaryHeading}
                        style={{ paddingBottom: 0 }}
                        id='specifications'
                        align='center' variant="h5" color="textPrimary">
                        Specifications
                      </Typography>
                      <Typography align='center' gutterBottom variant="subtitle2" color="textPrimary">
                        Click/Tap on sections below to view details
                      </Typography>
                    </Box>
                  </Grid>
                  <SpecificationsComponent />
                </Grid>
                <Grid container alignContent='center' justify='center' className={classes.sectionPadding}>
                  <Grid item xs={8} className={classes.yellowBorder} md={4}>
                  </Grid>
                </Grid>
              </>
              }
              <Grid container className={classes.sectionPadding}>
                <Grid item xs={12}>
                  <Typography className={classes.secondaryHeading} align='center' gutterBottom variant="h5" color="textPrimary">
                    Available Colors
                  </Typography>
                  {
                    (!loading && colors.length > 0) &&
                    <Carousel naturalSlideWidth={100}
                      naturalSlideHeight={60}
                      totalSlides={colors.length}
                      visibleSlides={3}
                      isPlaying={true}
                      infinite
                      innerClass={classes.slideMargin}
                      slides={colors}
                    />
                  }
                </Grid>
              </Grid>
              <Grid container alignContent='center' justify='center' className={classes.sectionPadding}>
                <Grid item xs={8} className={classes.yellowBorder} md={4}>
                </Grid>
              </Grid>
              <Grid container className={classes.sectionPadding}>
                <Grid item xs={12}>
                  <Typography className={classes.secondaryHeading} align='center'
                    id='images'
                    gutterBottom variant="h5" color="textPrimary">
                    Gallery
                  </Typography>
                  {
                    (!loading && carProfile?.gallery?.length > 0) &&
                    <Carousel naturalSlideWidth={100}
                      naturalSlideHeight={50}
                      totalSlides={carProfile.gallery.length}
                      isPlaying={true}
                      infinite
                      slides={carProfile.gallery}
                    />
                  }
                </Grid>
              </Grid>
            </div>
          }
        </Container>
      </main>
    </React.Fragment>
  );
}