import React, { useContext } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Finance } from '@red-build/leadcentre'
import FinanceBGImage from '../shared/assets/aksons-finance.jpg';
import { useParams } from "react-router-dom";
import { BASE_URI, MASTER_DEALERID, VIRTUAL_URI_APPLICATION } from '../shared/Constants'
import { NavLink as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';
import { FinanceContext } from '../shared/contexts/FinanceContext';
import { DealerContext } from '../shared/contexts/DealerContext';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(6, 0, 6),
    background: 'transparent',
  },
  divider: {
    backgroundColor: '#0089a1',
    width: '33%',
    marginBottom: '30px',
    height: '3px',
    margin: '20px auto'
  },
  success: {
    color: 'green',
    justifyContent: 'center'
  },
  yellow: {
    color: '#0089a1'
  },
  topHeader: {
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '2.5rem',
    marginBottom: 0
  },
  arrow: {
    width: 0,
    height: 0,
    borderLeft: '30px solid transparent',
    borderRight: '30px solid transparent',
    borderTop: '30px solid #0089a1',
    margin: '0 auto'
  },
  stepsIcon: {
    '& svg': {
      fontSize: '5rem',
      border: '2px solid #0089a1',
      borderRadius: '1rem',
      padding: '0.4rem'
    }
  },
  financeBackgroud: {
    backgroundImage: ({ FinanceBGImage }) => `url(${FinanceBGImage})`,
    paddingTop: '20px',
    paddingBottom: '20px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    minHeight: '600px',
  },
  TopTi: {
    fontSize: '40px',
    fontWeight: "unset",
    marginBottom: "50px"
  },
  accent: {
    color: '#0089a1'
  },
}));

const ApplyFinance = () => {
  const classes = useStyles({ FinanceBGImage });
  const { leadId } = useParams(false);
  let { globalModel } = useContext(FinanceContext);
  const { globalDealer, dealerList } = useContext(DealerContext);

  const financeQuestions = [
    {
      name: 'popi',
      labelComponent:
        <span>
          By submitting this form I agree to the <Link component={RouterLink} to="/terms" className={classes.link}>terms and conditions</Link> and <Link component={RouterLink} to="/privacy" className={classes.link}>privacy policies.</Link>
        </span>,
      correct: true,
      order: 4
    }
  ];

  return (
    <div className={classes.financeBackgroud}>
      <Container maxWidth="lg">
        <div className={classes.content}>
          <Typography
            gutterBottom
            variant="h1"
            align="center"
            color="textPrimary"
            fontWeight="500"
            className={classes.TopTi}
          >
            Apply for <span className={classes.accent}>Finance</span>
          </Typography>
          <Grid container alignItems="center" justify="center">
            <Grid item sm={12} md={6}>
              {((!leadId) && globalDealer) &&
                <Finance
                  base_uri={BASE_URI}
                  pqType="no_pq"
                  dealerType={"multi_dealer"}
                  dealers={dealerList}
                  masterDealerId={MASTER_DEALERID}
                  dealerId={globalDealer.dealerId}
                  financeQuestions={financeQuestions}
                  stockId={globalModel.stockId}
                  variantId={globalModel.variantId}
                  colorId={globalModel.colorId}
                  applicationSubmission={true}
                  applicationUrl={VIRTUAL_URI_APPLICATION}
                  dealerMakesOnly
                  activeMakeId={
                    globalDealer?.relatedBrands?.length > 1 ? 0 : globalDealer?.relatedBrands[0].id
                  }
                />}
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  )
};

export default ApplyFinance;