export const BASE_URI = process.env.REACT_APP_CONEXA_API_URL;
export const wePay4Cars_uri = "https://c2-wepay4cars-marketdealer.azurewebsites.net/api"
export const PAGE_SIZE = 20;
export const HAS_MULTIPLE_DEALERSHIPS = true
export const QUALIFYING_FINANCE_PERCENTAGE = 0.33;
export const DEALERID = 437
export const MASTER_DEALERID = 437
export const MASTER_CONFIGURATOR_DEALERID = 286 
export const MASTER_DEALER_NAME = "AksonsICar PMB Master"
export const MASTER_MOTORGROUPID = 5 
export const DEALERNAME = "AksonsICar PMB Master"
export const MOTORGROUPID = 5
export const VIRTUAL_URI_RANGE = `https://aksconfigurator.conexa.co.za/${DEALERID}/range/:leadId`
export const VIRTUAL_URI_DERIVATIVE = `https://aksconfigurator.conexa.co.za/${DEALERID}/:modelId/builder/:leadId`
export const VIRTUAL_URI_APPLICATION = `https://aksconfigurator.conexa.co.za/${DEALERID}/1/vehicle/finance/0/:leadId`
export const FINAPP_URI_BASE = 'https://dz6rhlcy38qhy.cloudfront.net';
export const FINAPP_URI_APPLICATION = `${FINAPP_URI_BASE}/${DEALERID}/1/vehicle/finance/0/:leadId`;
