import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    // width:'calc(100% / 2)',
    margin: '0 auto',
  },
  main: {
    "@media (max-width: 900px)": {
      margin: '30px auto',
    },
  },
  contentInner: {
    display: 'flex',
    flexDirection: 'column',
  },
  img: {
    height: "100%",
  },
}));

const ImageGrid = (props) => {
  const classes = useStyles();
  return (
    <Container maxWidth="lg" className={classes.main}>
      <Box>
        {/*<ImageContainer aspectRatio={(16/6)} height='100%' src={contactUsImage} alt='Contact Us' />*/}
      </Box>
      <div >
        <Grid container className={classes.content}>
          <Grid item xs={12} md={12} lg={12} className={classes.contentInner}>
            <div>
              <img alt="CardImage" src={props.image} width="100%" className={classes.img}></img>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.btnFlex}>
      </div>
    </Container>
  );
}

export default ImageGrid;