import React, { useRef, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { NavLink as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
import { BASE_URI, DEALERID, DEALERNAME } from './shared/Constants'
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import logoImage from './shared/assets/logoNew.png';
const drawerWidth = 240;

const navBarStyles = makeStyles((theme) => ({
  link: {
    padding: '0.2rem 1rem !important',
    cursor: 'pointer',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 0,
    fontFamily: 'Renault',
    position: 'relative',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    textDecoration: 'none',
    fontSize: '16px',
    '@media (max-width: 600px)': {
      display: 'none',
    },
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'transparent'
    },
  },
  mLink: {
    padding: '0.2rem 1rem !important',
    cursor: 'pointer',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 0,
    fontFamily: 'Renault',
    position: 'relative',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    textDecoration: 'none',
    fontSize: '16px',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'transparent'
    },
  },
  iconLink: {
    padding: '1rem 0rem !important',
    cursor: 'pointer',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 0,
    position: 'relative',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'transparent'
    },
  },
  active: {
    textDecoration: 'none',
    borderBottom: '3px solid #0089a1',
    color: '#0089a1!important',
    fontSize: '16px'
  },
  background: {
    background: '#ffffff',
    transition: 'all 0.2s',
    boxShadow: '1px 3px 6px 2px #91919154',
    '&:hover': {
      background: '#ffffff',
      linkNav: {
        color: 'black !important',
      }
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#404041',
  },
  white: {
  },
  bold: {
    fontWeight: 'lighter',
  },
  top: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '793px',
    padding: '5px 0px',
    '@media (min-width: 0px) and (max-width: 768px)': {
      marginLeft: '10px',
    },
    '@media (min-width: 769px) and (max-width: 1024px)': {
      marginLeft: '197px',
    },
    '@media (min-width: 1025px) and (max-width: 1280px)': {
      marginLeft: '197px',
    },
    '@media (min-width: 1281px) and (max-width: 1366px)': {
      marginLeft: '390px',
    },
    '@media (min-width: 1440px) and (max-width: 1600px)': {
      marginLeft: '421px',
    },
    '@media (min-width: 1680px) and (max-width: 1920px)': {
      marginLeft: '573px',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  logo: {
    height: 'auto',
    width: 200,
    '@media (max-width: 717px)': {
      maxWidth: '80px',
      paddingTop: '17px',
      paddingBottom: ' 12px'
    }
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
  badgeButton: {
    '&:hover': {
      background: 'transparent',
      color: '#5c92a7'
    },
    '&:focus': {
      outline: 'none'
    },
    '&:active': {
      color: '#5c92a7'
    }
  },
  flexContainer: {
    display: 'flex',
    direction: 'row',

  },
  topNav: {
    display: 'flex',
    direction: 'row',
    background: '#7C878E',
    minHeight: 'unset',


  },
  topNavText: {
    display: 'flex',
    direction: 'row',
    background: '#7C878E',
    minHeight: 'unset',
    '@media (max-width: 717px)': {
      justifyContent: 'flex-end',
    }
  },
  flex: {
    display: 'flex',
    direction: 'row'
  },
  grow: {
    flexGrow: 1,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },

  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
      '@media (max-width: 410px)': {
        marginLeft: '3px'
      }
    },
  },
  iconButtonStyles: {
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
  },
  flexWrap: {
    '@media (max-width: 410px)': {
      flexWrap: 'wrap'
    }
  }
}));

export default function NavbarComponent() {
  const classes = navBarStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [navBarData, setNavBarData] = useState(false);
  const anchorRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;

    axios({
      method: 'GET',
      url: `${BASE_URI}/dealerWebsites/${DEALERID}`
    }).then((response) => {
      setNavBarData({ title: response.data.title, subTitle: response.data.subTitle, });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);


  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen)
  }

  const menuLinks = (
    <React.Fragment>
      <Hidden xsDown implementation="css">
      </Hidden>
    </React.Fragment>
  );

  return (
    <AppBar position="sticky" color="transparent" className={classes.background} elevation={0}>
      <Divider variant="middle" />
      <Toolbar className={classes.topNav} disableGutters>
        <div style={{ flex: 1, display: 'flex' }} className={classes.topNavText}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={`${classes.menuButton} ${classes.white}`}
          >
            <MenuIcon />
          </IconButton>
          {
            menuLinks
          }
        </div>
        <React.Fragment>
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true
              }}
            >
              <IconButton onClick={handleDrawerToggle} className={`${classes.closeMenuButton} ${classes.white}`}>
                <CloseIcon />
              </IconButton>
              <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}>
                <Link
                  variant="button"
                  color="textSecondary"
                  component={RouterLink}
                  // activeClassName={classes.active}
                  to='/'
                  className={`${classes.mLink} ${classes.white}`}
                  onClick={() => setMobileOpen(false)}
                  style={{border: 'none'}}
                >
                  Home
                </Link>
                <Link
                  variant="button"
                  color="textSecondary"
                  activeClassName={classes.active}
                  onClick={() => setMobileOpen(false)}
                  component={RouterLink}
                  to='/about-us'
                  className={`${classes.mLink} ${classes.white} `}
                  style={{border: 'none'}}
                >
                  About Us
                </Link>
                <Link
                  variant="button"
                  color="textSecondary"
                  className={`${classes.mLink} ${classes.white}`}
                  onClick={() => setMobileOpen(false)}
                  activeClassName={classes.active}
                  component={RouterLink} 
                  to='/showroom'
                  style={{border: 'none'}}
                >
                  New Vehicles
                </Link>
                <Link
                  variant="button"
                  color="textSecondary"
                  className={`${classes.mLink} ${classes.white} `}
                  activeClassName={classes.active}
                  onClick={() => setMobileOpen(false)}
                  component={RouterLink} to='/used-vehicles'
                  style={{border: 'none'}}
                >
                  Used Cars
                </Link>
                <Link
                  variant="button"
                  color="textSecondary"
                  component={RouterLink}
                  activeClassName={classes.active}
                  to='/apply-for-finance'
                  className={`${classes.mLink} ${classes.white}`}
                  onClick={() => setMobileOpen(false)}
                  style={{border: 'none'}}
                >
                  Finance
                </Link>
                <Link
                  variant="button"
                  color="textSecondary"
                  component={RouterLink}
                  to='/contact-us'
                  activeClassName={classes.active}
                  className={`${classes.mLink} ${classes.white}`}
                  onClick={() => setMobileOpen(false)}
                  style={{border: 'none'}}
                >
                  Contact Us
                </Link>
                <Link
                  variant="button"
                  color="textSecondary"
                  activeClassName={classes.active}
                  onClick={() => setMobileOpen(false)}
                  component={RouterLink} to='/tradein'
                  className={`${classes.mLink} ${classes.white} `}
                  style={{border: 'none'}}
                >
                  Sell Your Car
                </Link>
              </div>
            </Drawer>
          </Hidden>
        </React.Fragment>
      </Toolbar >
      <Grid container direction="row" className={classes.grid} justify={"center"}>
        <React.Fragment>
          <Grid item xs={3} md={4} lg={2}>
            <Toolbar className={classes.center}  >
              <Link component={RouterLink} to='/'>
                <img src={logoImage} className={classes.logo} alt={DEALERNAME} />
              </Link>
            </Toolbar>
          </Grid>
          <Grid item xs={0} md={6} lg={4} className={classes.flex}>
            <Link
              variant="button"
              color="textSecondary"
              activeClassName={classes.active}
              className={`${classes.link} ${classes.white} ${classes.hidden}`}
              component={RouterLink} to='/about-us' style={{ color: '#7C878E' }}>
              About Us
            </Link>
            <Link
              variant="button"
              color="textSecondary"
              className={`${classes.link} ${classes.white} ${classes.hidden}`}
              activeClassName={classes.active}
              onClick={handleDropdownClick}
              component={RouterLink} to='/showroom' style={{ color: '#7C878E' }}
            >
              New Vehicles
            </Link>
            <Link
              variant="button"
              color="textSecondary"
              className={`${classes.link} ${classes.white} ${classes.hidden}`}
              activeClassName={classes.active}
              onClick={handleDropdownClick}
              component={RouterLink} to='/used-vehicles' style={{ color: '#7C878E' }}
            >
              Used Cars
            </Link>
            <Link
              variant="button"
              color="textSecondary"
              className={`${classes.link} ${classes.white} ${classes.hidden}`}
              activeClassName={classes.active}
              onClick={handleDropdownClick}
              component={RouterLink} to='/apply-for-finance' style={{ color: '#7C878E' }}
            >
              Finance
            </Link>
            <Link
              variant="button"
              color="textSecondary"
              className={`${classes.link} ${classes.white} ${classes.hidden}`}
              activeClassName={classes.active}
              onClick={handleDropdownClick}
              component={RouterLink} to='/contact-us' style={{ color: '#7C878E' }}
            >
              Contact Us
            </Link>
            <Link
              variant="button"
              color="textSecondary"
              className={`${classes.link} ${classes.white} ${classes.hidden}`}
              activeClassName={classes.active}
              onClick={handleDropdownClick}
              component={RouterLink} to='/tradein' style={{ color: '#7C878E' }}
            >
              Sell Your Car
            </Link>
          </Grid>
        </React.Fragment>
      </Grid>
    </AppBar>
  )
}
