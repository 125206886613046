import React, { useEffect, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { NavLink as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: 'black',
        padding: theme.spacing(1, 15, 1),
        display: 'flex',
        alignItems: 'center',
        position:'absolute',
        zIndex:'99999',
        '@media (max-width: 426px)': {
            padding: theme.spacing(1, 1, 1 ,1),
            flexDirection:'column',
        },
        '@media (min-width: 426px) and (max-width: 1024px)': {
            padding: theme.spacing(1, 1, 1 ,1),
            flexDirection:'column',
        },
    },
    btn: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: theme.spacing(1, 5, 1),
        background:'#FFFFFF',
        color: 'black',
        border: 'none',
        borderRadius: '0px',
        marginRight:'10px',
        fontSize: '12px',
        whiteSpace:'nowrap',
        '@media (max-width: 426px)': {
            margin: theme.spacing(1, 1, 1 ,1),
            textAlign:'center'
        },
        '@media (min-width: 426px) and (max-width: 1024px)': {
            margin: theme.spacing(1, 5, 1 ,2),
            textAlign:'center'
        },
    },
    text: {
        color: 'white',
        margin: theme.spacing(1, 10, 1 ,1),
        width: '80%',
        fontSize:'14px',
        '@media (max-width: 426px)': {
            margin: theme.spacing(1, 1, 1 ,1),
            textAlign:'center'
        },
        '@media (min-width: 426px) and (max-width: 1024px)': {
            margin: theme.spacing(1, 5, 1 ,2),
            textAlign:'center'
        },
    },
    buttonHolder: {
        display: 'flex',
        padding: theme.spacing(0, 0, 0),
        height: '100%',
        alignItems: 'center',
        '@media (max-width: 426px)': {
            flexDirection:'column',
        },
    },
}));

const writeCookie = (name, value, days) => {
    var date, expires;
    if (days) {
        date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
        
    } else {
        expires = "";
    }
    document.cookie = name + "=" + value + expires + ";";

}

const getCookieValue = (a) => {
           
    var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';

};

let Banner;

export default function CookiePopUp() {

    const [hasValue, setHasValue] = useState(false);

    const setCookie = (val) => {
        setHasValue(true);

        if (val) {
            writeCookie('noCookie', true, 1);
        }
        else {
            writeCookie('noCookie', false, 365);
        }
    }

    useEffect(() => {

        if (getCookieValue('noCookie').length > 0) {
            setHasValue(true);
        }

        //if (getCookieValue('noCookie').length == 0 || getCookieValue('noCookie') == true) {
        //    window['ga-disable-UA-141787134-4'] = true;
        //    window['ga-disable-AW-428068664'] = true;
        //}
        //else {
        //    window['ga-disable-UA-141787134-4'] = false;
        //    window['ga-disable-AW-428068664'] = false;

        //    /*global fbq*/
        //    /*eslint no-undef: "error"*/
        //    fbq('init', '2296050297327711');
        //    fbq('track', 'PageView');

        //    var head = document.getElementsByTagName('head')[0];
        //    var script = document.createElement('script');
        //    script.type = 'text/javascript';
        //    script.src = 'https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-6028ed60b640aa23';
        //    head.appendChild(script);
        //}

    }, []);
   
    const classes = useStyles();

    if (!hasValue) {
        Banner = 
        <div className={classes.background}>
        <p className={classes.text}>
                This site uses cookies and related technologies for site operation, analytics and third party advertising purposes as described in our <Link component={RouterLink} to="/privacy" className={classes.link}>Privacy Policy.</Link>  If you continue to use this site without changing your device's cookie settings, you consent to the use of cookies on this site.
        </p>
        <div className={classes.buttonHolder}>
                <button
                    onClick={() => setCookie(false)}
        className={classes.btn}>
            PROCEED 
        </button>
        </div>;
        </div>;
        } else {
            Banner = ""
      
        }

    return (
        <div>
            {Banner}
        </div>
    )
}
