import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Image1 from "../shared/assets/credit-card.svg";
import Image2 from "../shared/assets/keys.svg";
import Image3 from "../shared/assets/assets.svg";
import Image4 from "../shared/assets/traffic-jam.svg";

const useStyles = makeStyles(() => ({
  root: {
    background: "#0089a1",
    padding: "20px 0px ",
  },
  title: {
    color: "white",
    fontSize: "2.8rem",
    margin: "20px 0px",
    marginBottom: "40px",
    fontWeight: 'bold',
  },
  accent: {
    color: "#404041",
  },
  flex: {
    display: "flex",
    justifyContent: "center",
    maxWidth: "1770px",
    margin: "0 auto",
    flexWrap: 'wrap',
  },
  card: {
    width: "calc(100% / 4 - 20px)",
    display: "flex",
    flexWrap: 'wrap',
    flexDirection: "column",
    alignItems: "center",
    "@media (min-width:0px) and (max-width:425px)": {
      minWidth: "calc(100% / 1 )",
    },
    "@media (min-width:426px) and (max-width:900px)": {
      minWidth: "calc(100% / 2 - 20px)",
    },
    "@media (min-width:901px) and (max-width:1222px)": {
      minWidth: "calc(100% / 3 - 20px)",
    }
  },
  cardImg: {
    background: "white",
    width: "45%",
    borderRadius: "30px",
    padding: "30px",
  },
  cardTextHolder: {
    color: "white",
    width: "40%",
    padding: "20px 0px",
    textAlign: "center",
    fontSize: "18px",
    "@media (min-width:0px) and (max-width:900px)": {
      width: "60%",
    }
  },
}));

const Banner = () => {
  const classes = useStyles();

  let cards = [
    {
      image: Image1,
      title: "Apply online with instant approval",
    },
    {
      image: Image2,
      title: "Sell your vehicle for a great price",
    },
    {
      image: Image3,
      title: "Arrange home delivery or Collect your vehicle",
    },
    {
      image: Image4,
      title: "Widest range of used vehicles available",
    },
  ];
  return (
    <div className={classes.root}>
      <Typography
        align="center"
        variant="h1"
        paragraph
        gutterBottom
        className={classes.title}
      >
        Get the car you want today, with {" "}
        <span className={classes.accent}>AksonsiCar</span>.
      </Typography>
      <div className={classes.flex}>
        {cards.map((c) => {
          return (
            <div className={classes.card}>
              <div className={classes.cardImg}>
                <img alt='Banner' src={c.image} width="100%" />
              </div>
              <div className={classes.cardTextHolder}>{c.title}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Banner;
