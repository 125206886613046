import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { ImageContainer } from "../shared/ImageContainer";
import { CurrencyValue } from "../shared/TextMask";
import Button from "@material-ui/core/Button";
import { NavLink as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import AddIcon from "@material-ui/icons/Add";
import VehicleController from "./VehicleController";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0, 6),
  },
  dividerTitle: {
    backgroundColor: "#0089a1",
    width: "33%",
    marginBottom: "30px",
    height: "3px",
    margin: "20px auto",
  },
  bold: {
    fontWeight: 700,
  },
  title: {
    fontSize: "1.5rem",
    "&>span": {
      fontWeight: 400,
    },
  },
  cardHolder: {
    margin: "15px",
    maxWidth: "29%",
    border: "1px solid #F0F0F0",
    borderRadius: "5px",
    "@media (max-width: 599px)": {
      margin: "19px",
      maxWidth: "100%",
      border: "1px solid #F0F0F0",
      borderRadius: "5px",
    },
    "@media (min-width:600px) and (max-width:767px)": {
      margin: "15px",
      maxWidth: "44%",
      border: "1px solid #F0F0F0",
      borderRadius: "5px",
    },
  },
  vehicleContainer: {
    "& h5, & p": {
      color: "#7c7c7c",
    },
    "&:hover img, &:hover p, &:hover h5": {
      color: "#1f2532",
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  btn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  image: {
    "& > div > div": {
      backgroundColor: "#ffffff !important",
    },
  },
}));

const OurVehicles = () => {
  const classes = useStyles();
  const { vehicles } = VehicleController();

  const cleanModel = (model) => {
    return model.toLowerCase().replace(/\//g, "-").replace(/ /g, "-");
  };

  return (
    <Container maxWidth="lg">
      <div className={classes.content} style={{ color: "#7C878E" }}>
        <Typography
          gutterBottom
          variant="h3"
          align="center"
          color="textPrimary"
          fontWeight="500"
          style={{ color: "#7C878E" }}
        >
          <strong>Research</strong> All Models
        </Typography>
        <Typography
          gutterBottom
          align="center"
          color="textPrimary"
          style={{ color: "#0089a1" }}
        >
          Find a new car by brand or body type
        </Typography>

        {/* <Divider className={classes.dividerTitle} /> */}
        <Grid container spacing={2}>
          {vehicles.map((vehicle, index) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={index}
                className={classes.cardHolder}
              >
                <Link
                  underline="none"
                  component={RouterLink}
                  to={"/models/" + cleanModel(vehicle.title)}
                >
                  <Box className={classes.vehicleContainer}>
                    <Box className={classes.image}>
                      <ImageContainer
                        aspectRatio={16 / 8}
                        height="100%"
                        src={vehicle.image}
                        alt={vehicle.title}
                      />
                    </Box>
                    <Box></Box>
                    <Box
                      mt={2}
                      mb={0}
                      textAlign="left"
                      component="h5"
                      className={`${classes.title} ${classes.bold}`}
                    >
                      {vehicle.title}
                    </Box>
                    <Box
                      mt={0}
                      mb={0}
                      textAlign="center"
                      component="h5"
                      className={`${classes.title} ${classes.bold}`}
                    >
                      {vehicle?.price > 0 ? (
                        <>
                          <Box component="span">From</Box>{" "}
                          <CurrencyValue value={vehicle.price} />{" "}
                          <span style={{ fontSize: "14px" }}>Excl Vat</span>
                        </>
                      ) : (
                        <>{"POA"}</>
                      )}
                    </Box>
                    <Box component="p" mt={0} mb={3} textAlign="left">
                      {"Single Model"}
                    </Box>
                    <Box mb={3} className={classes.btn}>
                      <Button
                        variant="contained"
                        gutterBottom
                        color="primary"
                        startIcon={<AddIcon />}
                        style={{
                          backgroundColor: "#0089a1",
                          borderRadius: "5px",
                        }}
                      >
                        View Details
                      </Button>
                    </Box>
                  </Box>
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Container>
  );
};

export default OurVehicles;
