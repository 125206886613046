import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NavLink as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { DEALERNAME } from '../shared/Constants'

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0, 6),
    'li': {
      display: 'block;'
    },
    'li: before': {
      content: 'counters(item, ".") ". "',
      counterIncrement: 'item'
    },
    'ol': {
      counterReset: 'item'
    },
    'li strong': {
      display: 'inline-block',
      margin: '10px 0'
    }
  },
  link: {
    color: '#7c7c7c',
    textDecoration: 'none',
    '&:hover': {
      color: '#1f2532',
      textDecoration: 'none'
    }
  }
}));

const Terms = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <div className={classes.content} >
        <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500" >
          PRIVACY POLICY
        </Typography>
        <p>
          <strong> {DEALERNAME.replace("Master", '')} </strong> recognises the importance of protecting your personal information. Therefore, we have put together a privacy policy and approach that respects and addresses your needs.
        </p>
        <p>
          We define personal information as all the information specific to you that you provide to us over the Internet or any other channel. This includes personal data as varied as the information you enter into an online application for a new account, questions you ask via e-mail or personal banking data.
        </p>
        <p>
          How does AksonsiCar use the personal information you provide? AksonsiCar has the highest regard for the privacy of its customers. AksonsiCar will use personal information as appropriate in the normal course of our business to provide the products and services you have requested. We will also use this information to provide positive identification of you when you contact us. On occasion, we also send e-mail updates on services and other materials we believe may be of importance or interest.
        </p>
        <p>
          Does AksonsiCar engages third parties that help it deliver its banner advertisements and other online communications. The third parties may collect and use information about AksonsiCar customers to help us understand the offers, promotions, and types of advertising that are most appealing to its customers. The personal information they collect is aggregated and cannot be linked to a person. If you do not wish your personal information to be used in this way, please notify us.
        </p>
        <p>
          <strong> {DEALERNAME.replace("Master", '')} </strong> engages third parties that help it deliver its banner advertisements and other online communications. The third parties may collect and use information about Aksons Inspecta Car customers to help us understand the offers, promotions, and types of advertising that are most appealing to its customers. The personal information they collect is aggregated and cannot be linked to a person. If you do not wish your personal information to be used in this way, please notify us.
        </p>
        <p>
          – Third party vendors, including Google and DoubleClick, show AksonsiCar ads on sites on the internet.
        </p>
        <p>
          – Third party vendors, including Google and DoubleClick, use cookies to serve ads based on a user’s prior visits to AksonsiCar website.
        </p>
        <p>
          – Users may opt out of Google and DoubleClick’s use of cookies by visiting the Google advertising opt-out page or by visiting the Network Advertising Initiative opt out page
        </p>
        <p>
          – Users may opt out of Google and DoubleClick’s use of cookies by visiting the Google advertising opt-out page or by visiting the Network Advertising Initiative opt out page.
        </p>
        <br />
        <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500" >
          Violation and Waiver
        </Typography>
        <p>
          Should you violate these Terms and Conditions or any other rights of, AksonsiCar reserves the right to pursue all legal and equitable remedies against you. If AksonsiCar should fail to enforce any right or provision in these Terms and Conditions, you agree that this failure does not constitute a waiver of such right or provision or of any other rights or provisions in these Terms and Conditions. If a court should find that one or more rights or provisions set forth in these Terms and Conditions are invalid, you agree that the remainder of the Terms and Conditions shall be enforceable and that, to the extent permitted by law, the court shall give effect to the parties’ intentions, as reflected in any such right or provision that has been declared invalid or unenforceable.
        </p>
        <br />
        <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500" >
          Tracking Analytics
        </Typography>
        <p>
          We use <Link component={RouterLink} to="/cookies" className={classes.link}>"cookies"</Link> on this webiste.
        </p>
        <div>
          <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
            Additional Links
          </Typography>
          <ul>
            <li>
              <Link component={RouterLink} to="/requestergws" className={classes.link}>Personal information request form</Link>
            </li>
            <li>
              <Link component={RouterLink} to="/privacy" className={classes.link}>Policies</Link>
            </li>
            <li>
              <Link component={RouterLink} to="/cookies" className={classes.link}>Cookie Policy</Link>
            </li>
            <li>
              <Link component={RouterLink} to="/terms" className={classes.link}>Terms & Conditions</Link>
            </li>
          </ul>
        </div>
      </div>
    </Container>
  )
}

export default Terms