import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  divider: {
    backgroundColor: '#ffce33',
    width: '33%',
    marginBottom: '30px',
    height: '3px',
    margin: '20px auto'
  },
  success: {
    color: 'green',
    justifyContent: 'center'
  },
  yellow: {
    color: '#0089a1'
  },
  topHeader: {
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '2.5rem',
    marginBottom: 0
  },
  arrow: {
    width: 0,
    height: 0,
    borderLeft: '30px solid transparent',
    borderRight: '30px solid transparent',
    borderTop: '30px solid #0089a1',
    margin: '0 auto'
  },
  stepsIcon: {
    '& svg': {
      fontSize: '5rem',
      border: '2px solid #0089a1',
      borderRadius: '1rem',
      padding: '0.4rem'
    }
  },
  successText: {
    fontSize: '1.2rem'
  },
  ctaButtons: {
    display: 'flex',
    justifyContent: 'space-around',
    '& > button:first-child': {
      '@media (max-width:780px)': {
        marginBottom: '1rem'
      }
    },
    '@media (max-width:780px)': {
      flexDirection: 'column'
    }
  },
  blackButton: {
    backgroundColor: '#000000',
    color: '#fbce32'
  }
}));

export default function PQDecline() {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <div className={classes.content}>
        <Grid container alignItems="center" justify="center">
          <Grid item sm={12} md={6}>
            <Box mb={6} mt={4}>
              <Typography align="center" className={classes.successText}>
                Unfortunately you do not qualify to purchase this vehicle
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
