import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    position: 'relative',
    '@media (max-width: 425px)': {
      height: '100%',
    },
  },
  bold: {
    fontWeight: 'normal!important'
  },
  carouselBack: {
    left: '1%',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%);',
    all: 'unset',
    cursor: 'pointer',
    backgroundColor: '#05141F',
    color: 'white',
    width: '42px',
    height: '42px',
    opacity: '0.4',
    '&:focus': {
    },
    '&:hover': {
      opacity: '1',
    }
  },
  carouselNext: {
    right: '1%',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%);',
    all: 'unset',
    cursor: 'pointer',
    backgroundColor: '#05141F',
    color: 'white',
    width: '42px',
    height: '42px',
    opacity: '0.4',
    '&:focus': {
    },
    '&:hover': {
      opacity: '1',
    }
  },
  carouselDots: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
    marginBottom: '15px',
    '& > button': {
      width: '15px',
      height: '15px',
      borderRadius: '50%',
      border: 'none',
      padding: '0',
      cursor: 'pointer',
      backgroundColor: '#fc3',
      marginRight: '10px',
    }
  },
  backgroundImage: {
    backgroundPosition: 'center',
    backgroundSize: 'cover!important',
    height: '100%!important',
    '@media (max-width: 425px)': {
      height: '100%!important',
    },
    '@media (max-width: 624px)': {
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top',
      height: '36%',
    },
  },
  slide: {
    '@media (max-width: 450px)': {
      height: '200px!important',
    },
    '@media (min-width: 451px) and (max-width: 812px)': {
      height: '250px!important',
    },
  },
  content: {
    top: '15%',
    lineHeight: '8px',
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 426px)': {
      marginLeft: '0%',
      top: '20%',
      textAlign: 'center',
    },
    '@media (min-width: 426px) and (max-width: 768px)': {
      top: '20%',
      textAlign: 'left',
      marginLeft: '-30%',
    },
  },
}));

const Carousel = ({ slides, innerClass, dots, ...props }) => {
  const classes = useStyles();

  return (
    <CarouselProvider
      {...props}
    >
      <div className={classes.sliderContainer}>
        <Slider>
          {
            slides && slides?.map((slide, index) =>
              <Slide innerClassName={innerClass} className={classes.slide} index={index}>
                <Image src={slide.img ? slide.img : slide} height="100vh" />
              </Slide>
            )
          }
        </Slider>
        {dots && <DotGroup className={classes.carouselDots} disableActiveDots={false} />}
        <ButtonBack className={classes.carouselBack}><ChevronLeftIcon style={{ fontSize: 40 }} /></ButtonBack>
        <ButtonNext className={classes.carouselNext}><ChevronRightIcon style={{ fontSize: 40 }} /></ButtonNext>
      </div>
    </CarouselProvider>
  )
}

export default Carousel;