import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { BASE_URI, wePay4Cars_uri } from "./Constants"
import Success from "../shared/Success";
import Typography from "@material-ui/core/Typography";
import { SellYourVehicleWePay4Cars } from '@red-build/leadcentre'
import { DealerContext } from "./contexts/DealerContext";

export default function TradeInContainer(props) {
  const { dealerList } = useContext(DealerContext);
  const success = false;
  const LeadSource = 15;
  const defaultColors = {
    primaryColor: "#0089a1",
    secondaryColor: "#EFDF00",
  };

  return <Container style={{ marginTop: '50px' }}>
    <Grid item md={12} style={{ textAlign: 'center' }}>
      <Typography align={"center"} variant={"h1"} color="textPrimary" fontWeight="500" style={{ fontSize: '25px', fontWeight: 'bold' }}>
        Please Tell us more about your vehicle, you wish to trade in.
      </Typography>

    </Grid>
    {(success) && <Success />}
    {(!success) &&
      <>
        <SellYourVehicleWePay4Cars
          base_uri={BASE_URI}
          wePay4Cars_uri={wePay4Cars_uri}
          dealerId={76}
          leadSourceId={LeadSource}
          websiteColors={defaultColors}
          dealerList={dealerList}
          resetForm={true}
          licenseDiskScanner={false}
          filterCat={true}
          newCarLink="https://www.google.com"
          usedCarLink="https://www.google.com"
          //onSubmitForm={(formValues: any) => { printFormValues(formValues) }}
          includeAvailability={true}
        />
      </>
    }
  </Container>
}
