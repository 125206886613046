import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Banner from '../shared/assets/warranty-banner.jpg'
import { DEALERNAME } from '../shared/Constants'

const useStyles = makeStyles((theme) => ({
  bannerHeader: {
    backgroundColor: '#fc3',
    fontWeight: 700
  },
  info: {
    paddingBottom: '1rem'
  }
}));

const WarrantyPage = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
        5 Year/150 000km Warranty
      </Typography>
      <Box mb={2}>
        <img alt="Warranty Banner" style={{ width: '100%' }} src={Banner} />
      </Box>
      <Typography gutterBottom variant="h6" color="textPrimary" fontWeight="500">
        The {DEALERNAME} Vehicle and Parts Warranty will offer you complete peace of mind.
      </Typography>
      <div className={classes.info}>
        <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
          Parts Warranty
        </Box>
        <Box>
          <p>
            {DEALERNAME} warrants all parts and accessories supplied by {DEALERNAME} and fitted at the time of purchase of the new vehicle to be free of defects in
            material or workmanship under normal use for a period of 12 months.
          </p>
          <p>
            Parts & Accessories purchased over the counter are covered from the invoice date by {DEALERNAME}’s 1 Year/20,000km warranty, whichever occurs first.
          </p>
          <p>
            In the event that a genuine {DEALERNAME} part or accessory supplied by {DEALERNAME} proves to be defective in material or workmanship under normal use
            during the warranty period, {DEALERNAME} will repair or replace the part and/or accessory free of charge, excludes wear and tear parts.
          </p>
        </Box>
      </div>
      <div className={classes.info}>
        <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
          Vehcile Warranty
        </Box>
        <Box>
          <p>5 year 150 000km Warranty for all models ordered and registered from 11 Jan 2010.</p>
          <strong>Duration of the Warranty</strong>
          <p>
            Your new vehicle is protected by this {DEALERNAME} New Vehicle Warranty. This means the vehicle is guaranteed on the terms and conditions
            set out in the warranty policy against any defects relating to material, fitting or manufacturing fault under the Warranty for a
            period of 60 months from the date of delivery or 150 000km whichever comes first.
          </p>
          <strong>Benefits to the Customer</strong>
          <p>
            The warranty covers the cost of repair or replacement of defective parts to repair a material, assembly or manufacturing defect recognized by the
            manufacturer when carried out by a member of the {DEALERNAME} network. It also covers consequential damage to the vehicle resulting from the
            principal defect. It is up to the discretion of {DEALERNAME}, in consultation with the relevant {DEALERNAME} Network Member, to decide whether it is
            appropriate in the circumstances to repair or replace any fault part.
          </p>
        </Box>
      </div>
      <div className={classes.info}>
        <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
          What is {DEALERNAME} 's Encore Warranty Extension?
        </Box>
        <Box>
          <p>Pre-Owned Warranty Extension ({DEALERNAME} Encore Warranty Extension)</p>
          <p>
            Designed for the pre-owned {DEALERNAME} vehicle customer, {DEALERNAME} Encore Warranty Extension is designed to give the client financial
            protection in the event of unexpected mechanical breakdown or failure. It covers the pre-owned {DEALERNAME} vehicle on expiry of the
            manufacturer warranty for a further 24 months or 100,000km whichever comes first.
          </p>
        </Box>
      </div>
      <div className={classes.info}>
        <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
          Why {DEALERNAME} Encore?
        </Box>
        <Box>
          <ul>
            <li>{DEALERNAME} Encore Warranty Extension offers a product that will add the best value at the most cost effective level.</li>
            <li>This has been designed to ensures peace of mind motoring.</li>
          </ul>
        </Box>
      </div>
      <div className={classes.info}>
        <Box pt={1} pb={1} className={classes.bannerHeader} fontWeight="500" textAlign="center">
          The benefits of {DEALERNAME} Encore Warranty
        </Box>
        <Box>
          <ul>
            <li>Optimizes Resale Value of the {DEALERNAME} vehicle.</li>
            <li>Purchasing a pre-owned {DEALERNAME} with all the values and guarantees that have come to be expected</li>
            <li>A continuation of the {DEALERNAME} Manufacturers Warranty*.</li>
            <li>The {DEALERNAME} Encore Warranty Extension covers the cost of repair or replacement of defective parts and for potential Mechanical Product Defects.</li>
            <li>Allows the {DEALERNAME} vehicle to be repaired using approved {DEALERNAME} parts and {DEALERNAME} trained technicians.</li>
          </ul>
          * 5yr/ 150,000km for vehicles retailed post 11 January 2010
        </Box>
      </div>
    </React.Fragment>
  );
}

export default WarrantyPage;