import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useIntersectionObserver = ({
  target,
  onIntersect,
  threshold = 0.1,
  rootMargin = "0px"
}) => {
  React.useEffect(() => {
    const observer = new IntersectionObserver(onIntersect, {
      rootMargin,
      threshold
    });
    const current = target.current;
    observer.observe(current);
    return () => {
      observer.unobserve(current);
    };
  });
};

const useStyles = makeStyles({
  ImageContainerUsedVehicle: {
    position: 'relative',
    overflow: 'hidden',
    height: '400px!important',
    background: '#06141f',
    width: '100%',
    paddingTop: '0px',
    display: "flex",
    alignItems: "center"
  },
  image: {
    paddingTop: '0px',
    top: '0',
    bottom: '0',
    height: 'auto',
    width: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: '0 auto',
  }
});

export const ImageContainerUsedVehicleSingle = props => {
  const ref = React.useRef();
  const [isVisible, setIsVisible] = React.useState(false);
  const classes = useStyles(props);

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        setIsVisible(true);
        observerElement.unobserve(ref.current);
      }
    }
  });

  return (
    <div
      ref={ref}
      className={classes.ImageContainerUsedVehicle}>
      {isVisible && (
        <img className={classes.image} src={props.src} alt={props.alt}
          imageStyle={{
            height: 'initial',
            bottom: 0,
            margin: 'auto',
            position: 'relative',
          }}
          aspectRatio={props.aspectRatio ? props.aspectRatio : (16 / 9)} color='lightgrey' />
      )}
    </div>
  );
};
