import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { ImageContainerUsedVehicle } from "../shared/ImageContainerUsedVehicle";
import { CurrencyValue } from "../shared/TextMask";
import Button from "@material-ui/core/Button";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { useHistory } from "react-router-dom";
import { FinanceContext } from '../shared/contexts/FinanceContext';
import ComingSoon from "../shared/assets/comingsoon.jpg";

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 700,
  },
  flex: {
    display: "flex",
    flexDirection: "row!important",
    justifyContent: "space-between",
  },
  flexWrap: {
    display: "flex",
    flexDirection: "row!important",
    justifyContent: "space-between",
    flexWrap: 'wrap',
    "@media (min-width:0px) and (max-width:425px)": {
      flexDirection: "column!important",
    },
  },
  title: {
    fontSize: "1.0rem",
    "&>span": {
      fontWeight: 400,
    },
  },
  btnGrey: {
    display: "flex",
    background: '#404041',
    flexDirection: "column",
    alignItems: "end",
    marginTop: "2%",
    width: "45%",
    textTransform: 'lowercase',
    borderRadius: '2rem',
  },
  btn2: {
    minWidth: "100%",
  },
  image: {
    "& > div > div": {
      backgroundColor: "#ffffff !important",
    },
  },
  carsLoadContainer: {
    display: "flex",
    justifyContent: "center",
  },
  CarsLoad: {
    margin: "auto",
  },
  flexCard: {
    width: 'calc(100% / 3 )',
    marginBottom: '20px',
    "@media (min-width:0px) and (max-width:425px)": {
      width: 'calc(100% / 1 )',
    },
    "@media (min-width:426px) and (max-width:900px)": {
      minWidth: "calc(100% / 2 - 5px)",
    },
    "@media (min-width:901px) and (max-width:1222px)": {
      minWidth: "calc(100% / 3 - 20px)",
    }
  },
  vehicleContainer: {
    margin: '0px 10px',
    border: '1px solid black',
    borderRadius: '2rem',
    "& h5, & p": {
      color: "#7c7c7c",
    },
    "&:hover img, &:hover p, &:hover h5": {
      color: "#1f2532",
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  btn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginTop: "2%",
    width: "45%",
    borderRadius: '40px',
    background: "#404041",
  },
  btn1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginTop: "2%",
    width: "45%",
    color: "white",
    background: "#0089a1",
    border: "none!important",
    borderRadius: '40px'
  },
  textHolder: {
    padding: "20px"
  },
  brand: {
    padding: "0px",
    margin: "0px",
    fontSize: '1.8rem'
  },
  modelRange: {
    padding: "0px",
    margin: "0px",
    color: '#25d1ef!important'
  },
  titleSmall: {
    padding: "0px",
    margin: "0px",
    fontSize: '0.8rem'
  },
  price: {
    fontSize: "2.5rem!important",
    textAlign: 'left',
    fontWeight: "bold!important",
    color: '#0089a1!important',
    "&>span": {
      fontWeight: 400,
    },
  },
}));

const UsedVehiclesHome = ({ vehicles, onSelectView }) => {
  const history = useHistory();
  const classes = useStyles();
  let { setGlobalModel } = useContext(FinanceContext);

  const buildName = (vehicle) => {
    if (!vehicle) return "";
    return vehicle.brand + " " + vehicle.model;
  };

  const applyOnline = (vehicle) => {
    setGlobalModel(vehicle);
    history.push(`/apply-for-finance`)
  };

  return (
    <MuiThemeProvider >
      <div md={12} className={classes.flexWrap} >
        {vehicles.slice(0, 6).map((vehicle, index) => {
          return (
            <Grid key={index} className={classes.flexCard}>
              <Box className={classes.vehicleContainer}>
                <Box className={classes.image}
                  onClick={() => onSelectView(vehicle)}>
                  <ImageContainerUsedVehicle
                    aspectRatio="auto auto"
                    height="222px"
                    position="relative"
                    src={vehicle.image ? vehicle.image : ComingSoon}
                    alt={buildName(vehicle.title)}
                  />
                </Box>
                <div className={classes.textHolder} >
                  <Grid
                    item
                    md={12}
                    mt={0}
                    mb={0}
                    className={`${classes.flex} ${classes.bold}`}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <p className={classes.brand}>{vehicle.year} {vehicle.brand}</p>
                      <p className={classes.modelRange}>{vehicle.model}</p>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} className={classes.price}>
                    <CurrencyValue value={vehicle.price} />
                  </Grid>
                  <p className={classes.titleSmall}>
                    {vehicle.mileage} km |{" "}
                    {vehicle.transmission} |{" "}
                    {vehicle.colour}
                  </p>
                  <Box className={classes.flex}>
                    <Button
                      variant="contained"
                      gutterBottom
                      className={classes.btn1}
                      onClick={() => onSelectView(vehicle)}
                    >
                      VIEW
                    </Button>
                    <Button
                      variant="contained"
                      gutterBottom
                      className={classes.btn}
                      color="primary"
                      onClick={() => applyOnline(vehicle)}
                    >
                      Enquire Now
                    </Button>
                  </Box>
                </div>
              </Box>
            </Grid>
          );
        })}
      </div>
    </MuiThemeProvider>
  );
};

export default UsedVehiclesHome;