import React, { useState, useRef, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Card from "../Card/Card";
import ImageGrid from "../Grid/ImageGrid";
import { BASE_URI, DEALERID } from "../shared/Constants";
import axios from "axios";
import PietermaritzburgNorthDealer from "../shared/assets/Pietermaritzburg_North.jpg";
import PietermaritzburgCentralDealer from "../shared/assets/Pietermaritzburg_Central.jpg";
import beijingX550 from "../shared/assets/beijingX550.jpg";
import beijingX551 from "../shared/assets/beijingX551.jpg";
import beijingX552 from "../shared/assets/beijingX552.jpg";
import beijingX553 from "../shared/assets/beijingX553.jpg";
import UsedVehicles from "../usedCarUsedVehiclesPageHome/usedCarUsedVehiclesPageHome";
import Banner from "../shared/assets/aksons-banner.png";
import BannerSection from "../Banner/Banner";
import SharedCarousel from '../shared/SharedCarousel';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  introContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0, 6),
    maxWidth: '1770px',
    margin: "0 auto",
    "@media (max-width: 624px)": {
      paddingTop: 0,
    },
    "@media (min-width: 555px) and (max-width: 624px)": {
      marginTop: "40px",
    },
    "@media (min-width: 512px) and (max-width: 554px)": {
      marginTop: "50px",
    },
    "@media (min-width: 480px) and (max-width: 511px)": {
      marginTop: "40px",
    },
    "@media (min-width: 430px) and (max-width: 479px)": {
      marginTop: "45px",
    },
    "@media (min-width: 400px) and (max-width: 429px)": {
      marginTop: "30px",
    },
    "@media (min-width: 360px) and (max-width: 399px)": {
      marginTop: "40px",
    },
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
    alignItems: "center",
    textAlign: "center",
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  threeSteps: {
    backgroundColor: "#E7E9E9",
    color: "#7C878E",
  },
  redbtn: {
    backgroundColor: "#0089a1",
    color: "#ffffff",
    border: "1px solid #0089a1",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#0089a1",
      borderRadius: "2px",
    },
  },
  featureDeal: {
    borderBottom: "1px solid #cccccc",
    paddingTop: "20px",
    paddingBottom: "20px",
    "&:last-child": {
      borderBottom: 0,
    },
  },
  xlbutton: {
    backgroundColor: "#0089a1",
    padding: "13px 36px",
    borderRadius: "5px",
    fontSize: "1.1rem",
    "&:hover": {
      backgroundColor: "#0089a1",
      borderRadius: "2px",
    },
  },
  noPadding: {
    paddingBottom: 0,
  },
  flexCard: {
    display: "flex",
    maxWidth: '1770px',
    margin: '0 auto',
    justifyContent: 'space-around',
    padding: '10px',
    "@media (max-width: 900px)": {
      flexDirection: "column",
      padding: '0px',
    },
  },
  banner: {
    width: "100vw",
    backgroundImage: `url(${Banner})`,
    height: "60vh",
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    "@media (max-width: 768px)": {
      backgroundPosition: "center",
      backgroundSize: "cover",
      height: "30vh",
    },
  },
  featureDealImage: {
    "@media (max-width: 599px)": {
      height: "auto",
    },
  },
  centeredContent: {
    "@media (max-width: 1279px)": {
      justifyContent: "center",
    },
  },
  accent: {
    color: "#0089a1",
  },
  title: {
    paddingTop: "40px",
    fontSize: '2.8rem',
    fontWeight: 'bold',
  },
}));

export default function HomeComponent() {
  const classes = useStyles();
  const mountedRef = useRef(true);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getSlides = async () => {
      try {
        const params = {
          pageNumber: 0,
          pageSize: 100,
          status: "active",
          dealerId: DEALERID,
        };

        const result = await axios({
          method: "GET",
          url: `${BASE_URI}/slide`,
          params,
          cancelToken: source.token,
        });

        let tempSlides = result.data.list.map((itm) => {

          return {
            img: itm.slideImageUrl,
            isVideo: itm.isVideo,
            targetUrl: itm.url
          };
        });

        setSlides(tempSlides);
      } catch (error) {
        console.warn('HomeComponent.getSlides error:', error);
      }
    };

    getSlides();

    return () => {
      mountedRef.current = false;
      source.cancel();
    };
  }, []);

  return (
    <React.Fragment>
      <main>
        {/* Hero unit */}
        {
          slides?.length > 0 &&
          <SharedCarousel
            slides={slides}
          />
        }
        <BannerSection />
        <Container maxWidth="false">
          <Typography
            align="center"
            variant="h2"
            paragraph
            gutterBottom
            className={classes.title}
          >
            Shop at any of our <span className={classes.accent}>AksonsiCar</span> Locations
          </Typography>
          <Grid className={classes.flexCard}>
            <Grid item xs={12} md={5}>
              <Card
                branch={"Pietermaritzburg Central"}
                image={PietermaritzburgCentralDealer}
                location={""}
                id={23}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <Card
                branch={"Pietermaritzburg North"}
                image={PietermaritzburgNorthDealer}
                location={""}
                id={24}
              />
            </Grid>
          </Grid>
          <div className={classes.introContent}>
            <Typography
              align="center"
              paragraph
              gutterBottom
              style={{ fontSize: "1.4rem", color: "#7C878E" }}
            >
              At AksonsiCar we offer a professional experience and excellent
              after-sales service. Whether you know what you’re looking for or
              not, explore our high-quality car types, top brands to find the
              perfect vehicle for you.
            </Typography>
            <Typography
              variant="subtitle2"
              align="center"
              style={{ color: "#7C878E" }}
              gutterBottom
            >
              <Box fontWeight="fontWeightBold">
                We have a national network of dealers to assist you and we
                accept trade-in’s and if you require a test drive, we come to
                you!
              </Box>
            </Typography>
          </div>
        </Container>
        <Container maxWidth="false">
          <Grid className={classes.flexCard}>
            <Grid item xs={12} md={5}>
              <ImageGrid
                image={beijingX550}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <ImageGrid
                image={beijingX551}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <ImageGrid
                image={beijingX552}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <ImageGrid
                image={beijingX553}
              />
            </Grid>
          </Grid>
        </Container>
        <UsedVehicles />
      </main>
    </React.Fragment>
  );
}