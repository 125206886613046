import grandSedonaLarge from '../shared/assets/models/Grand Sedona/CarImages.SedonaLarge.jpg'
import grandSedonaSmall from '../shared/assets/models/Grand Sedona/CarImages.SedonaSmall.jpg'
import grandSedonaMedium1 from '../shared/assets/models/Grand Sedona/CarImages.SedonaMedium1.jpg'
import grandSedonaMedium2 from '../shared/assets/models/Grand Sedona/CarImages.SedonaMedium2.jpg'
import grandSedonaGallery1 from '../shared/assets/models/Grand Sedona/CarImages.SedonaGallery1.jpg'
import grandSedonaGallery2 from '../shared/assets/models/Grand Sedona/CarImages.SedonaGallery2.jpg'
import grandSedonaGallery3 from '../shared/assets/models/Grand Sedona/CarImages.SedonaGallery3.jpg'
import grandSedonaGallery4 from '../shared/assets/models/Grand Sedona/CarImages.SedonaGallery4.jpg'
import grandSedonaGallery5 from '../shared/assets/models/Grand Sedona/CarImages.SedonaGallery5.jpg'
import grandSedonaGallery6 from '../shared/assets/models/Grand Sedona/CarImages.SedonaGallery6.jpg'

import k2500Large from '../shared/assets/models/K2500/CarImages.K2500Large.jpg'
import k2500Small from '../shared/assets/models/K2500/CarImages.K2500Small.jpg'
import k2500Medium1 from '../shared/assets/models/K2500/CarImages.K2500Medium1.jpg'
import k2500Medium2 from '../shared/assets/models/K2500/CarImages.K2500Medium2.jpg'
import k2500Gallery1 from '../shared/assets/models/K2500/K2500Gallery1.jpg'
import k2500Gallery2 from '../shared/assets/models/K2500/K2500Gallery2.jpg'
import k2500Gallery3 from '../shared/assets/models/K2500/K2500Gallery3.jpg'
import k2500Gallery4 from '../shared/assets/models/K2500/K2500Gallery4.jpg'
import k2500Gallery5 from '../shared/assets/models/K2500/K2500Gallery5.jpg'
import k2500Gallery6 from '../shared/assets/models/K2500/K2500Gallery6.jpg'

import picantoLarge from '../shared/assets/models/Picanto/CarImages.PicantoLarge.jpg'
import picantoSmall from '../shared/assets/models/Picanto/CarImages.PicantoSmall.jpg'
import picantoMedium1 from '../shared/assets/models/Picanto/CarImages.PicantoMedium1.jpg'
import picantoMedium2 from '../shared/assets/models/Picanto/CarImages.PicantoMedium2.jpg'
import picantoGallery1 from '../shared/assets/models/Picanto/CarImages.PicantoGallery1.jpg'
import picantoGallery2 from '../shared/assets/models/Picanto/CarImages.PicantoGallery2.jpg'
import picantoGallery3 from '../shared/assets/models/Picanto/CarImages.PicantoGallery3.jpg'
import picantoGallery4 from '../shared/assets/models/Picanto/CarImages.PicantoGallery4.jpg'
import picantoGallery5 from '../shared/assets/models/Picanto/CarImages.PicantoGallery5.jpg'
import picantoGallery6 from '../shared/assets/models/Picanto/CarImages.PicantoGallery6.jpg'


import picantoRunnerLarge from '../shared/assets/models/Picanto Runner/CarImages.PRunnerLarge.jpg'
import picantoRunnerSmall from '../shared/assets/models/Picanto Runner/CarImages.PRunnerSmall.jpg'
import picantoRunnerMedium1 from '../shared/assets/models/Picanto Runner/CarImages.PRunnerMedium1.jpg'
import picantoRunnerMedium2 from '../shared/assets/models/Picanto Runner/CarImages.PRunnerMedium2.jpg'
import picantoRunnerGallery1 from '../shared/assets/models/Picanto Runner/CarImages.PRunnerGallery1.jpg'
import picantoRunnerGallery2 from '../shared/assets/models/Picanto Runner/CarImages.PRunnerGallery2.jpg'
import picantoRunnerGallery3 from '../shared/assets/models/Picanto Runner/CarImages.PRunnerGallery3.jpg'
import picantoRunnerGallery4 from '../shared/assets/models/Picanto Runner/CarImages.PRunnerGallery4.jpg'
import picantoRunnerGallery5 from '../shared/assets/models/Picanto Runner/CarImages.PRunnerGallery5.jpg'
import picantoRunnerGallery6 from '../shared/assets/models/Picanto Runner/CarImages.PRunnerGallery6.jpg'

import rioLarge from '../shared/assets/models/Rio/CarImages.RioLarge.jpg'
import rioSmall from '../shared/assets/models/Rio/CarImages.RioSmall.jpg'
import rioMedium1 from '../shared/assets/models/Rio/CarImages.RioMedium1.jpg'
import rioMedium2 from '../shared/assets/models/Rio/CarImages.RioMedium2.jpg'
import rioGallery1 from '../shared/assets/models/Rio/CarImages.RioGallery1.jpg'
import rioGallery2 from '../shared/assets/models/Rio/CarImages.RioGallery2.jpg'
import rioGallery3 from '../shared/assets/models/Rio/CarImages.RioGallery3.jpg'
import rioGallery4 from '../shared/assets/models/Rio/CarImages.RioGallery4.jpg'
import rioGallery5 from '../shared/assets/models/Rio/CarImages.RioGallery5.jpg'
import rioGallery6 from '../shared/assets/models/Rio/CarImages.RioGallery6.jpg'

import seltosLarge from '../shared/assets/models/Seltos/CarImages.SeltosLarge.jpg'
import seltosSmall from '../shared/assets/models/Seltos/CarImages.SeltosSmall.jpg'
import seltosMedium1 from '../shared/assets/models/Seltos/CarImages.SeltosMedium1.jpg'
import seltosMedium2 from '../shared/assets/models/Seltos/CarImages.SeltosMedium2.jpg'
import seltosGallery1 from '../shared/assets/models/Seltos/SeltosGallery1.jpg'
import seltosGallery2 from '../shared/assets/models/Seltos/SeltosGallery2.jpg'
import seltosGallery3 from '../shared/assets/models/Seltos/SeltosGallery3.jpg'
import seltosGallery4 from '../shared/assets/models/Seltos/SeltosGallery4.jpg'
import seltosGallery5 from '../shared/assets/models/Seltos/SeltosGallery5.jpg'
import seltosGallery6 from '../shared/assets/models/Seltos/SeltosGallery6.jpg'

import sonetLarge from '../shared/assets/models/Sonet/CarImages.SonetLarge.jpg'
import sonetSmall from '../shared/assets/models/Sonet/CarImages.SonetSmall.jpg'
import sonetMedium1 from '../shared/assets/models/Sonet/CarImages.SonetMedium1.jpg'
import sonetMedium2 from '../shared/assets/models/Sonet/CarImages.SonetMedium2.jpg'
import sonetGallery1 from '../shared/assets/models/Sonet/SonetGallery1.jpg'
import sonetGallery2 from '../shared/assets/models/Sonet/SonetGallery2.jpg'
import sonetGallery3 from '../shared/assets/models/Sonet/SonetGallery3.jpg'
import sonetGallery4 from '../shared/assets/models/Sonet/SonetGallery4.jpg'
import sonetGallery5 from '../shared/assets/models/Sonet/SonetGallery5.jpg'
import sonetGallery6 from '../shared/assets/models/Sonet/SonetGallery6.jpg'

import sorentoLarge from '../shared/assets/models/Sorento/CarImages.SorentoLarge.jpg'
import sorentoSmall from '../shared/assets/models/Sorento/CarImages.SorentoSmall.jpg'
import sorentoMedium1 from '../shared/assets/models/Sorento/CarImages.SorentoMedium1.jpg'
import sorentoMedium2 from '../shared/assets/models/Sorento/CarImages.SorentoMedium2.jpg'
import sorentoGallery1 from '../shared/assets/models/Sorento/CarImages.SorentoGallery1.jpg'
import sorentoGallery2 from '../shared/assets/models/Sorento/CarImages.SorentoGallery2.jpg'
import sorentoGallery3 from '../shared/assets/models/Sorento/CarImages.SorentoGallery3.jpg'
import sorentoGallery4 from '../shared/assets/models/Sorento/CarImages.SorentoGallery4.jpg'
import sorentoGallery5 from '../shared/assets/models/Sorento/CarImages.SorentoGallery5.jpg'
import sorentoGallery6 from '../shared/assets/models/Sorento/CarImages.SorentoGallery6.jpg'


import sportageLarge from '../shared/assets/models/Sportage/CarImages.SportagLarge.jpg'
import sportageSmall from '../shared/assets/models/Sportage/CarImages.SportagSmall.jpg'
import sportageMedium1 from '../shared/assets/models/Sportage/CarImages.SportagMedium1.jpg'
import sportageMedium2 from '../shared/assets/models/Sportage/CarImages.SportagMedium2.jpg'
import sportageGallery1 from '../shared/assets/models/Sportage/CarImages.SportagGallery1.jpg'
import sportageGallery2 from '../shared/assets/models/Sportage/CarImages.SportagGallery2.jpg'
import sportageGallery3 from '../shared/assets/models/Sportage/CarImages.SportagGallery3.jpg'
import sportageGallery4 from '../shared/assets/models/Sportage/CarImages.SportagGallery4.jpg'
import sportageGallery5 from '../shared/assets/models/Sportage/CarImages.SportagGallery5.jpg'
import sportageGallery6 from '../shared/assets/models/Sportage/CarImages.SportagGallery6.jpg'

import pegasLarge from '../shared/assets/models/Pegas/CarImages.PegasLarge.jpg'
import pegasSmall from '../shared/assets/models/Pegas/CarImages.PegasSmall.jpg'
import pegasMedium1 from '../shared/assets/models/Pegas/CarImages.PegasMedium1.jpg'
import pegasMedium2 from '../shared/assets/models/Pegas/CarImages.PegasMedium2.jpg'
import pegasGallery1 from '../shared/assets/models/Pegas/PegasGallery1.jpg'
import pegasGallery2 from '../shared/assets/models/Pegas/PegasGallery2.jpg'
import pegasGallery3 from '../shared/assets/models/Pegas/PegasGallery3.jpg'
import pegasGallery4 from '../shared/assets/models/Pegas/PegasGallery4.jpg'
import pegasGallery5 from '../shared/assets/models/Pegas/PegasGallery5.jpg'
import pegasGallery6 from '../shared/assets/models/Pegas/PegasGallery6.jpg'

import k2700Large from '../shared/assets/models/K2700/CarImages.K2700Large.jpg'
import k2700Small from '../shared/assets/models/K2700/CarImages.K2700Small.jpg'
import k2700Medium1 from '../shared/assets/models/K2700/CarImages.K2700Medium1.jpg'
import k2700Medium2 from '../shared/assets/models/K2700/CarImages.K2700Medium2.jpg'
import k2700Gallery1 from '../shared/assets/models/K2700/K2700Gallery1.jpg'
import k2700Gallery2 from '../shared/assets/models/K2700/K2700Gallery2.jpg'
import k2700Gallery3 from '../shared/assets/models/K2700/K2700Gallery3.jpg'
import k2700Gallery4 from '../shared/assets/models/K2700/K2700Gallery4.jpg'
import k2700Gallery5 from '../shared/assets/models/K2700/K2700Gallery5.jpg'
import k2700Gallery6 from '../shared/assets/models/K2700/K2700Gallery6.jpg'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  grandSedonaLarge,
  grandSedonaSmall,
  grandSedonaMedium1,
  grandSedonaMedium2,
  grandSedonaGallery1,
  grandSedonaGallery2,
  grandSedonaGallery3,
  grandSedonaGallery4,
  grandSedonaGallery5,
  grandSedonaGallery6,

  k2500Large,
  k2500Small,
  k2500Medium1,
  k2500Medium2,
  k2500Gallery1,
  k2500Gallery2,
  k2500Gallery3,
  k2500Gallery4,
  k2500Gallery5,
  k2500Gallery6,

  picantoLarge,
  picantoSmall,
  picantoMedium1,
  picantoMedium2,
  picantoGallery1,
  picantoGallery2,
  picantoGallery3,
  picantoGallery4,
  picantoGallery5,
  picantoGallery6,


  picantoRunnerLarge,
  picantoRunnerSmall,
  picantoRunnerMedium1,
  picantoRunnerMedium2,
  picantoRunnerGallery1,
  picantoRunnerGallery2,
  picantoRunnerGallery3,
  picantoRunnerGallery4,
  picantoRunnerGallery5,
  picantoRunnerGallery6,

  rioLarge,
  rioSmall,
  rioMedium1,
  rioMedium2,
  rioGallery1,
  rioGallery2,
  rioGallery3,
  rioGallery4,
  rioGallery5,
  rioGallery6,

  seltosLarge,
  seltosSmall,
  seltosMedium1,
  seltosMedium2,
  seltosGallery1,
  seltosGallery2,
  seltosGallery3,
  seltosGallery4,
  seltosGallery5,
  seltosGallery6,

  sonetLarge,
  sonetSmall,
  sonetMedium1,
  sonetMedium2,
  sonetGallery1,
  sonetGallery2,
  sonetGallery3,
  sonetGallery4,
  sonetGallery5,
  sonetGallery6,

  sorentoLarge,
  sorentoSmall,
  sorentoMedium1,
  sorentoMedium2,
  sorentoGallery1,
  sorentoGallery2,
  sorentoGallery3,
  sorentoGallery4,
  sorentoGallery5,
  sorentoGallery6,

  sportageLarge,
  sportageSmall,
  sportageMedium1,
  sportageMedium2,
  sportageGallery1,
  sportageGallery2,
  sportageGallery3,
  sportageGallery4,
  sportageGallery5,
  sportageGallery6,

  pegasLarge,
  pegasSmall,
  pegasMedium1,
  pegasMedium2,
  pegasGallery1,
  pegasGallery2,
  pegasGallery3,
  pegasGallery4,
  pegasGallery5,
  pegasGallery6,

  k2700Large,
  k2700Small,
  k2700Medium1,
  k2700Medium2,
  k2700Gallery1,
  k2700Gallery2,
  k2700Gallery3,
  k2700Gallery4,
  k2700Gallery5,
  k2700Gallery6,
}