import React from 'react';
import MaintenancePage from './MaintenancePage';
import WarrantyPage from './WarrantyPage';
import TomTomPage from './TomTomPage';
import ServicePlansPage from './ServicePlansPage';
import BodyshopsPage from './BodyshopsPage';
import RoadsideAssistance from './RoadsideAssistancePage';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Navigation from './Navigation';

const AfterSalesComponent = props => {

  const section = () => {
    switch (props.match.path) {
      case '/maintenance':
        return <MaintenancePage />;
      case '/warranty':
        return <WarrantyPage />;
      case '/tomtom-live':
        return <TomTomPage />;
      case '/service-plans':
        return <ServicePlansPage />;
      case '/bodyshops':
        return <BodyshopsPage />;
      case '/roadside-assistance':
        return <RoadsideAssistance />;
      default: return null;
    }
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item sm={12} md={8}>
          {section()}
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Navigation />
        </Grid>
      </Grid>
    </Container>
  )
}

export default AfterSalesComponent;