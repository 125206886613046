import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { NavLink as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    // width:'calc(100% / 2)',
    margin: '0 auto',
  },
  main: {
    "@media (max-width: 900px)": {
      margin: '30px auto',
    },
  },
  contentInner: {
    backgroundColor: '#0089a1',
    borderRadius: '2rem',
    display: 'flex',
    flexDirection: 'column',
  },
  headingHolder: {
    textAlign: 'center',
    color: 'white',
    margin: '5px auto'
  },
  bold: {
    fontWeight: 700
  },
  img: {
    borderRadius: '2rem',
    height: "100%",
  },
  title: {
    fontSize: '2rem',
    fontWeight: "bold"
  },
  btnFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    "@media (max-width: 900px)": {
      flexDirection: 'column',
    },
  },
  btn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "5%",
    cursor: 'pointer',
    width: "45%",
    fontSize: '20px',
    fontWeight: '400',
    padding: "10px 10px",
    textAlign: 'center',
    color: "white",
    borderRadius: '40px',
    background: "#404041",
    "@media (max-width: 900px)": {
      width: "unset",
    },
  },
  btn1: {
    display: "flex",
    cursor: 'pointer',
    fontSize: '20px',
    fontWeight: '400',
    flexDirection: "column",
    alignItems: "center",
    marginTop: "5%",
    width: "45%",
    padding: "10px 10px",
    color: "white",
    textAlign: 'center',
    background: "#0089a1",
    border: "none!important",
    borderRadius: '40px',
    "@media (max-width: 900px)": {
      width: "unset",
    },
  },
}));

const Card = (props) => {
  const classes = useStyles();
  let st = `/used-vehicles/dealer/${props.id}`
  return (
    <Container maxWidth="lg" className={classes.main}>
      <Box>
        {/*<ImageContainer aspectRatio={(16/6)} height='100%' src={contactUsImage} alt='Contact Us' />*/}
      </Box>
      <div >
        <Grid container className={classes.content}>
          <Grid item xs={12} md={12} lg={12} className={classes.contentInner}>
            <Box className={classes.headingHolder} mb={0} mt={0}>
              <div pb={0} className={classes.title}>{props.branch}</div>
            </Box>
            <div>
              <img alt="CardImage" src={props.image} width="100%" className={classes.img}></img>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.btnFlex}>
        <Link
          className={classes.btn}
          activeClassName={classes.active}
          component={RouterLink}
          to="/contact-us"
        >
          Contact
        </Link>
        <Link
          className={classes.btn1}
          activeClassName={classes.active}
          component={RouterLink}
          to={st}
        >
          View Stock
        </Link>
      </div>
    </Container>
  );
}

export default Card;