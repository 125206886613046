/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { BASE_URI } from '../shared/Constants';
import ContactComponent from '../shared/ContactComponent'
import axios from 'axios';
import { DealerContext } from '../shared/contexts/DealerContext';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0, 6),
  },
  dividerTitle: {
    backgroundColor: '#0089a1',
    width: '33%',
    marginBottom: '0px',
    height: '3px',
    margin: '20px auto'
  },
  bold: {
    fontWeight: 700
  },
  flexCard: {
    display: 'flex',

    '@media (max-width: 900px)': {
      flexDirection: 'column'
    },
  },
  url: {
    color: '#7c7c7c',
    textDecoration: 'none',
    '&:hover': {
      color: '#1f2532',
      textDecoration: 'none'
    }
  },
  link: {
    backgroundColor: '#0089a1',
    marginBottom: '1rem',
    color: '#ffffff',
    fontWeight: '700',
    '&:hover': {
      textDecoration: 'none',
      opacity: 0.8
    }
  },
  staffFlex: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  staffCardFlex: {
    background: '#0089a1',
    display: 'flex',
    width: "calc(100% / 4 - 20px)",
    margin: '10px',
    flexDirection: 'column',
    borderRadius: '1rem',
    "@media (min-width:0px) and (max-width:425px)": {
      width: 'calc(100% / 1 )',
    },
    "@media (min-width:426px) and (max-width:900px)": {
      minWidth: "calc(100% / 2 - 5px)",
    },
    "@media (min-width:901px) and (max-width:1222px)": {
      minWidth: "calc(100% / 3 - 20px)",
    }
  },
  staffCardFlexText: {
    background: '#0089a1',
    borderRadius: '1rem',
    color: "white",
    textAlign: 'center',
    padding: '10px',
    flexDirection: 'column'
  },
  staffCardFlexImg: {
    borderRadius: '1rem',
  }
}));

const ContactUs = () => {
  const classes = useStyles();
  const [staff, setStaff] = useState()
  const { globalDealer } = useContext(DealerContext)
  useEffect(() => {
    axios.get(`${BASE_URI}/DealerStaff`, {
    }).then(result => {
      let data = result.data.filter((s) => s.dealerId == globalDealer.dealerId)
      setStaff(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Container maxWidth="lg">
      <Box>
        {/*<ImageContainer aspectRatio={(16/6)} height='100%' src={contactUsImage} alt='Contact Us' />*/}
      </Box>
      <div className={classes.content}>
        <Typography gutterBottom variant="h1" align="center" color="textPrimary" fontWeight="500" style={{ fontSize: '25px', fontWeight: 'bold' }}>
          Our Customer Relations team are here to help you
        </Typography>
        <Divider className={classes.dividerTitle} />
        <Box mb={3} mt={3}>
          <Box textAlign="center" fontSize={'1.5rem'}>
            <Typography gutterBottom variant="h2" align="center" color="textPrimary" fontWeight="500" style={{ fontSize: '25px' }}>
              Send us your details and we will call you
            </Typography>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Box fontWeight="fontWeightBold">Address</Box>
            <Box>Pietermaritzburg,</Box>
            <Box mb={3}>302 Hoosen Haffajee street</Box>
            <Box fontWeight="fontWeightBold">Sales</Box>
            <Box mb={3} fontWeight="fontWeightBold">033 392 8500</Box>
            <div class="mapouter">
              <div class="gmap_canvas">
                <iframe title="Address" width="100%" height="200" id="gmap_canvas" src="https://maps.google.com/maps?q=302%20Hoosen%20Haffajee%20street&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
              </div>
            </div>
            <Box fontWeight="fontWeightBold">Address</Box>
            <Box>Pietermaritzburg,</Box>
            <Box mb={3}>1 Chatterton Road, 3201</Box>
            <Box fontWeight="fontWeightBold">Sales</Box>
            <Box mb={3} fontWeight="fontWeightBold">033 342 4600</Box>
            <div class="mapouter">
              <div class="gmap_canvas">
                <iframe title="Address" width="100%" height="200" id="gmap_canvas" src="https://maps.google.com/maps?q=1%20Chatterton%20Road,%203201&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
              </div>
            </div>
            <Box fontWeight="fontWeightBold">Opening Hours</Box>
            <Box>Monday - Friday 08:00 – 17:00</Box>
            <Box>Saturday: 08:00-13:00</Box>
            <Box mb={3}>Sunday: Closed</Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <ContactComponent leadTypeId={3} />
          </Grid>
          <div className={classes.staffFlex}>
            {staff?.map((s) => {
              return (
                <div className={classes.staffCardFlex}>
                  <div className={classes.staffCardFlexText} >
                    {s.fullName}
                    <div>
                      {s.email}
                    </div>
                    <div>
                      {s.contactNumber}
                    </div>
                  </div>
                  <img alt="StaffPhoto" src={s.photoUrl} width="100%" className={classes.staffCardFlexImg} />
                </div>
              )
            })}
          </div>
        </Grid>
      </div>
    </Container>
  );
}

export default ContactUs;