import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Banner from '../shared/assets/roadside-assistance-banner.jpg'
import { DEALERNAME } from '../shared/Constants'

const useStyles = makeStyles((theme) => ({
  info: {
    paddingBottom: '1rem'
  }
}));

const RoadsideAssistancePage = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
        24Hr Roadside Assistance
      </Typography>
      <Box mb={2}>
        <img style={{ width: '100%' }} alt="Tom Tom" src={Banner} />
      </Box>
      <Typography gutterBottom variant="h6" color="textPrimary" fontWeight="500">
        Simply dial 0861 736 2858 for assistance.
      </Typography>
      <div className={classes.info}>
        <Box>
          <ul>
            <li>This number is available 24 hours a day, 365 days a year.</li>
            <li>You will either be assisted on the scene or your vehicle will be towed to the nearest {DEALERNAME}  dealership.</li>
            <li>Our 24 hour Roadside Assistance Plan covers all {DEALERNAME}  vehicles for a period of 5 years or 150 000km with an option for extension.</li>
          </ul>
        </Box>
      </div>
    </React.Fragment>
  )
}

export default RoadsideAssistancePage;