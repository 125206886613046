/* eslint-disable eqeqeq */
import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import PaymentDetails from '../shared/PaymentDetails'
import { ImageContainerUsedVehicleSingle } from '../shared/ImageContainerUsedVehicleSingle'
import Carousel from '../homePage/CarouselSingle'
import { useParams, useHistory, useLocation } from "react-router-dom";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BASE_URI } from '../shared/Constants';
import Container from '@material-ui/core/Container';
import { CurrencyValue } from '../shared/TextMask';
import { FinanceContext } from '../shared/contexts/FinanceContext';
import { Formik, Form } from "formik";
import ComingSoon from "../shared/assets/comingsoon.jpg";
import { useMetaTags } from '@red-build/leadcentre';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0, 6),
  },
  largeButton: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    width: '100%',
    '& h6': {
      fontSize: '28.8px'
    }
  },
  buttonIconLg: {
    fontSize: '5rem',
    color: '#06141f'
  },
  height: {
    height: '100px!important'
  },
  sectionPadding: {
    margin: '3rem 0'
  },
  paragraphPadding: {
    padding: '1.5rem 3rem !important'
  },
  heading: {
    borderBottom: '5px solid #06141f',
    textTransform: 'capitalize',
    paddingBottom: '.5rem',
    fontWeight: '700',
    display: 'inline-block'
  },
  yellowBorder: {
    borderBottom: '5px solid #06141f',
  },
  secondaryHeading: {
    textTransform: 'uppercase',
    paddingBottom: '1rem',
    fontWeight: '400'
  },
  slideMargin: {
    display: 'flex',
    width: 'calc(100% - 20px)',
    height: 'auto',
    left: '10px',
    top: '10px',
    background: '#06141f',
    objectFit: 'cover',
  },
  topHeader: {
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '2.5rem'
  },
  yellow: {
    color: '#06141f'
  },
  specs: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& .specs': {
      borderBottom: '0 !important',
      marginTop: '-25px'
    },
  },
  specsInner: {
    boxShadow: '1px 3px 6px 2px #91919154',
    borderRadius: '5px',
    margin: "10px",
    padding: "15px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: 'calc(100% / 4 - 20px)',
    "@media (min-width:0px) and (max-width:425px)": {
      width: 'calc(100% / 1 )',
    },
    "@media (min-width:426px) and (max-width:900px)": {
      minWidth: "calc(100% / 2 - 20px)",
    },
    "@media (min-width:901px) and (max-width:1222px)": {
      minWidth: "calc(100% / 3 - 20px)",
    }
  },
  formControl: {
    minWidth: 400,
    '@media (max-width: 450px)': {
      minWidth: '100%'
    }
  },
  headerLine: {
    height: '2px',
    width: '100%',
    backgroundColor: '#06141f',
    padding: '0 !important'
  },
  smallHeading: {
    fontSize: '1.3rem'
  },
  smallHeadingC: {
    color: '#0089a1'
  },
  lighter: {
    fontWeight: 'lighter',
    fontSize: '1.0rem',
    padding: '0 !important'
  },
  stepPaddingColours: {
    marginLeft: "1em",
    marginRight: "1em",
    marginTop: "0.5em",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  colorsContainer: {
    display: 'flex',
    '& > div:first-child': {
      marginLeft: 0
    }
  },
  colourName: {
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    marginTop: '0.6rem'
  },
  colourPrice: {
    fontWeight: 700
  },
  stepOneButtons: {
    display: 'flex',
    '@media (max-width: 500px)': {
      flexDirection: 'column',
      alignItems: 'center',
      '& > a': {
        marginLeft: '0 !important',
        marginRight: '0 !important'
      }
    }
  },
  topDetailSection: {
    '@media screen and (max-width: 959px)': {
      display: 'flex',
      flexDirection: 'column',
      '& > div:first-child': {
        order: 2,
        marginTop: '2rem'
      },
      '& > div:nth-child(2)': {
        order: 3
      },
      '& > div:nth-child(3)': {
        order: 4
      },
      '& > div:nth-child(4)': {
        marginTop: '-1.5rem'
      }
    }
  },
  darkgreyleft: {
    fontWeight: 'bold',
    borderBottom: '#cccccc  1px solid',
  },
  darkgreyright: {
    textAlign: 'right',
    borderBottom: '#cccccc  1px solid',
  },
  flex: {
    display: 'flex',
    flexDirection: 'row!important',
    justifyContent: 'space-between',
  },
  flexM: {
    '@media (min-width: 800px)': {
      display: 'flex',
      flexDirection: 'row!important',
      justifyContent: 'space-between',
    },
  },
  ml: {
    boxShadow: '1px 3px 6px 2px #91919154',
    padding: "15px",
    borderRadius: "5px",
    '@media (min-width: 800px)': {
      marginLeft: '1rem'
    },
  },
  btn: {
    display: 'flex',
    borderRadius: '40px',
    alignItems: 'center',
    width: '45%',
    justifyContent: 'center',
    textAlign: 'center',
  },
  btn1: {
    display: 'flex',
    borderRadius: '40px',
    alignItems: 'center',
    width: '45%',
    background: '#404041',
    color: "white",
    border: '1px solid black',
    justifyContent: 'center',
  },
  btn2: {
    alignItems: 'start',
    marginTop: '2%',
    width: '100%',
  },
}));

export default function CarProfileContainer({ searchLoading, activeVehicle, onFilterData }) {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  let vehicleProfile;
  // eslint-disable-next-line no-unused-vars
  const [carProfile, setCarProfile] = useState(vehicleProfile);
  const [compLoading, setLoading] = useState(true);
  const [financeDetails, setFinanceDetails] = useState({})
  const [modelData, setModelData] = React.useState([]);
  const [images, setImages] = useState([]);
  const [activeImage, setActiveImage] = useState(null);
  let { setGlobalFinance, setGlobalModel } = useContext(FinanceContext);
  const { updateMetaTags } = useMetaTags();
  const { pathname } = useLocation();

  const values =
  {
    model: "",
    modelId: "",
  };

  const calculateMonthly = (data) => {
    var deposit = data.purchasePrice * (data.deposit / 100);
    var totalPrice = data.purchasePrice;
    var initFee = 0;
    var principal = totalPrice - deposit + initFee;
    var balloonPerc = data.balloonPayment;
    var balloonAmt = totalPrice * balloonPerc / 100;
    var interestRate = data.linkedInterestRate;
    var interestPM = interestRate / 100 / 12;
    var repaymentPeriod = data.term;
    var days = 1;
    var v = 1 / (1 + interestPM);
    var d = 1 - v;
    var y = Math.pow(v, repaymentPeriod - 1);
    var comp = (1 - y) / d;
    var fp = principal * (Math.pow(1 + interestPM, (days / (365 / 12).toFixed())));
    var monthly = 0;

    if (parseInt(balloonAmt) > 0) {
      comp = (1 - (Math.pow(v, repaymentPeriod - 1))) / d;
      monthly = (fp - (balloonAmt * Math.pow(v, repaymentPeriod - 1))) / comp;
    }
    else {
      comp = (1 - (Math.pow(v, repaymentPeriod))) / d;
      monthly = fp / comp;
    }
    return round(monthly) + initFee;
  }

  function round(x) {
    return Math.round(x * 100) / 100;
  }

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    setLoading(true)
    const source = CancelToken.source();

    axios.get(`${BASE_URI}/stock/${params?.stockId}`, {
      cancelToken: source.token
    }).then(result => {
      let data = result.data
      setModelData(data);
      setGlobalModel(data);

      var tmpFinanceDetails = {
        monthlyInstallment: 0,
        term: 72,
        linkedInterestRate: 9,
        deposit: 0,
        balloonPayment: 0,
        originalPrice: data.price,
        purchasePrice: data.price
      };
      let monthly = calculateMonthly(tmpFinanceDetails);
      tmpFinanceDetails = { ...tmpFinanceDetails, monthlyInstallment: monthly };

      setFinanceDetails(tmpFinanceDetails);
      setGlobalFinance(tmpFinanceDetails);
      setImages(data.mainUrls.map(itm => { return { img: itm }; }));
      setLoading(false)
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVehicle, params]);

  useEffect(() => {
    if (images.length > 0) {
      setActiveImage(images[0])
    }
    updateMetaTags({
      pathname,
      title: [modelData.model, 'Used Vehicles', 'AksonsiCar'],
      description: [
        modelData?.model,
        `R ${financeDetails?.purchasePrice}`,
        'AksonsiCar',
        modelData?.year,
        `${modelData?.mileage} km`
      ],
      image: images[0],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images, modelData, financeDetails])

  const ImageClick = (index) => {
    setActiveImage(images[index])
  }

  return (
    <React.Fragment>
      {
        compLoading &&
        <Grid item container justifyContent='center' justify='center' alignContent='center' xs={12}>
          <CircularProgress size={80} />
        </Grid>
      }
      {
        !compLoading &&
        <Container container md={10} style={{ marginRight: '2rem' }} className={classes.flexM}>
          <Grid item xs={12} md={7}>
            <Box >
              <ImageContainerUsedVehicleSingle aspectRatio={(16 / 9)} src={activeImage.img ? activeImage.img : ComingSoon} alt='MainImage' className={classes.height} />
            </Box>
            {images.length > 1 &&
              <Box style={{ marginBottom: '1rem' }}>
                {
                  <Carousel naturalSlideWidth={150}
                    naturalSlideHeight={100}
                    totalSlides={images.length}
                    visibleSlides={4}
                    isPlaying={true}
                    infinite
                    innerClass={classes.slideMargin}
                    slides={images}
                    onSlideClick={(index) => ImageClick(index)}
                  />
                }
              </Box>
            }
            <div className={classes.specs} mt={5}>
              {/* eslint-disable-next-line array-callback-return */}
              {modelData.features.split(",").map((f) => {
                if (f !== " ") {
                  return (
                    <div className={classes.specsInner}>
                      {f}
                    </div>
                  )
                }
              })}
            </div>
          </Grid>
          <Grid item xs={12} md={5} className={classes.ml}>
            <Formik
              initialValues={values}
              isInitialValid={false}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  actions.setSubmitting(true);
                }, 100);
              }}
            >
              {() => {
                return (
                  <Form>
                    <Box ml={1}>
                      <Box mb={1} mt={1}>
                        <Typography className={classes.smallHeading}>
                          {modelData.model}
                        </Typography>
                        <Typography className={classes.smallHeading}>
                          <p className="clear">Stock No : {modelData.stockNo}</p>
                        </Typography>
                      </Box>
                      <div className={classes.headerLine}></div>
                      <Box mb={1} mt={1}>
                        <Typography variant="h4" color="textPrimary" className={classes.smallHeadingC}>
                          <strong>
                            <CurrencyValue value={financeDetails.purchasePrice} />
                          </strong>
                        </Typography>
                        <Typography variant="p" color="textPrimary">
                          <span className={classes.lighter}>
                            From <CurrencyValue value={financeDetails.monthlyInstallment} /> pm
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                    <PaymentDetails displayPrice={false} details={financeDetails ? financeDetails : carProfile.dealDetails} />
                    <Box mb={3} mt={3} className={`${classes.stepOneButtons} ${classes.flex}`} style={{ width: 'initial', display: 'flex' }}>
                      <Button variant="contained" gutterBottom onClick={() => { history.push(`/contact-us`) }} color="primary" className={classes.btn}>
                        Enquire Now
                      </Button>
                      <Button variant="contained" gutterBottom onClick={() => { history.push(`/apply-for-finance`) }} className={classes.btn1}>
                        Apply Now
                      </Button>
                    </Box>
                    <Box style={{ marginTop: 24 }}>
                      <table border="0" width="100%" cellspacing="0px" cellpadding="15px">
                        <tbody>
                          <tr >
                            <td className={classes.darkgreyleft}>
                              Year
                            </td>
                            <td className={classes.darkgreyright}>
                              {modelData.year}
                            </td>
                          </tr>
                          <tr >
                            <td className={classes.darkgreyleft}>
                              Mileage
                            </td>
                            <td className={classes.darkgreyright}>
                              {modelData.mileage} km
                            </td>
                          </tr>
                          <tr>
                            <td className={classes.darkgreyleft}>
                              Colour
                            </td>
                            <td className={classes.darkgreyright}>
                              {modelData.colour}
                            </td>
                          </tr>
                          <tr >
                            <td className={classes.darkgreyleft}>
                              Body
                            </td>
                            <td className={classes.darkgreyright}>
                              {modelData.bodyType}
                            </td>
                          </tr>
                          <tr >
                            <td className={classes.darkgreyleft}>
                              Transmission
                            </td>
                            <td className={classes.darkgreyright}>
                              {modelData.transmission == "M" ? "Manual" : "Automatic"}
                            </td>
                          </tr>
                          <tr >
                            <td className={classes.darkgreyleft}>
                              Fuel Type
                            </td>
                            <td className={classes.darkgreyright}>
                              {modelData.fuelType == "P" ? "Petrol" : "Diesel"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Box>
                    <Box>
                      <Typography style={{ marginTop: '.5rem' }} variant="caption" color="textSecondary">
                        {financeDetails.termsAndConditions}
                      </Typography>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Container>
      }
    </React.Fragment>
  );
}