import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useParams, useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import ApplySteps from '../shared/ApplySteps';
import { FinanceContext } from '../shared/contexts/FinanceContext';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  divider: {
    backgroundColor: '#ffce33',
    width: '33%',
    marginBottom: '30px',
    height: '3px',
    margin: '20px auto'
  },
  success: {
    color: 'green',
    justifyContent: 'center'
  },
  yellow: {
    color: '#0089a1'
  },
  topHeader: {
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '2.5rem',
    marginBottom: 0
  },
  arrow: {
    width: 0,
    height: 0,
    borderLeft: '30px solid transparent',
    borderRight: '30px solid transparent',
    borderTop: '30px solid #0089a1',
    margin: '0 auto'
  },
  stepsIcon: {
    '& svg': {
      fontSize: '5rem',
      border: '2px solid #0089a1',
      borderRadius: '1rem',
      padding: '0.4rem'
    }
  },
  successText: {
    fontSize: '1.2rem'
  },
  ctaButtons: {
    display: 'flex',
    justifyContent: 'space-around',
    '& > button:first-child': {
      '@media (max-width:780px)': {
        marginBottom: '1rem'
      }
    },
    '@media (max-width:780px)': {
      flexDirection: 'column'
    }
  },
  blackButton: {
    backgroundColor: '#000000',
    color: '#fbce32'
  }
}));

export default function GetInfoContainer() {
  const classes = useStyles();

  const { leadId } = useParams();
  const history = useHistory();
  let { globalLead, globalVehicle } = useContext(FinanceContext);

  const stepsState = {
    one: {
      active: false,
      completed: true
    },
    two: {
      active: false,
      completed: true
    },
    three: {
      active: true,
      completed: false
    }
  }

  return (
    <Container maxWidth="lg">
      <div className={classes.content}>
        <Box mb={4}>
          <ApplySteps horizontal disabledStates={stepsState} />
        </Box>
        <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500">
          <Box mb={5} className={classes.topHeader}>
            <span>Congratulations {globalLead.name}</span>
          </Box>
        </Typography>
        <Grid container alignItems="center" justify="center">
          <Grid item sm={12} md={6}>
            <Box mb={6} mt={4}>
              <Typography align="center" className={classes.successText}>
                You have been <span className={classes.yellow}>pre-qualified</span> for your new {globalVehicle.model}. To conclude your finance you can complete all your financial details and upload your documents,
                alternatively you can opt for one of our qualified Sales team to contact you to conlude the transaction.
                Please check your phone for an SMS with all the details.
              </Typography>
              <Box mb={2} mt={2}>
                <Typography variant="h6" align="center">
                  What would you like to do next?
                </Typography>
              </Box>
              <Box className={classes.ctaButtons}>
                <Button
                  className={classes.blackButton}
                  variant={"contained"}
                  color="primary"
                  onClick={() => {
                    //window.location.href = VIRTUAL_URI_APPLICATION.replace(':leadId', leadId);
                  }}
                >
                  Request a call back
                </Button>
                <Button
                  className={classes.button}
                  variant={"contained"}
                  color="primary"
                  onClick={() => {
                    history.push('/apply-for-finance/done/' + leadId);
                  }}
                >
                  Complete process online
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
