import CarImages from './CarImages';

export const tmpDealgrandSedona = {
  title: ' Get a great deal on the Kia Grand Sedona',
  model: 'Kia Grand Sedona',
  infoMedia: {
    large: CarImages.grandSedonaLarge,
    small: CarImages.grandSedonaSmall,
    medium1: CarImages.grandSedonaMedium1,
    medium2: CarImages.grandSedonaMedium2,
  },
  carInfo: {
    mainTitle: 'Style that turns heads anywhere',
    introContent: `Style is all about delivering what is expected and then going beyond. It’s about leaving nothing to chance and paying absolute attention to every last detail and the finest craftsmanship. But it's also about pleasure and enjoying the luxury of freedom. The new Kia Grand Sedona will take you wherever you desire in outstanding comfort and style, making driving a truly satisfying part of how you fill each day with pleasure.`,
    subTitle1: 'Ultimate relaxation',
    subContent1: 'The perfect interior environment offers an innovative approach to comfort by making sure you feel refreshed in all driving conditions while having everything you need at your fingertips. Live life to the fullest and explore the limits of your potential in outstanding comfort and style.',
    subTitle2: 'Definition of space',
    subContent2: 'An abundance of space is key to creating an interior ambience that is truly relaxing. With the all-new Kia Grand Sedona, you and your passengers will discover that, however far you travel, it is possible to arrive at your destination even more energized than when you left.',
    subTitle3: 'Smarter features',
    subContent3: 'Smarter features to match your fast pace of life, Quality of life is all about feeling you have more than enough time to spare. The all-new Kia Grand Sedona is designed to offer optimum efficiency and simplicity to help make your life easier and allow you to enjoy every precious moment.'
  },
  gallery: [
    CarImages.grandSedonaGallery1,
    CarImages.grandSedonaGallery2,
    CarImages.grandSedonaGallery3,
    CarImages.grandSedonaGallery4,
    CarImages.grandSedonaGallery5,
    CarImages.grandSedonaGallery6,
  ],
  financeOptions: [
    {
      variant: 'Kia Grand Sedona 2.2 CRDI EX 7 Seater AT',
      modelId: 73,
      estimatedPayments: 728995,
      monthlyInstallment: 9255,
      id: 0
    }
  ],
  dealDetails: {
    purchasePrice: 728995,
    monthlyInstallment: 9255,
    term: 72,
    linkedInterestRate: 9,
    deposit: 0,
    balloonPayment: 40,
    termsAndConditions: `Excludes on the road costs, bank monthly service fee of R69 and includes one off initiation fee of R1140. Deal is subject to the approval terms and conditions of MFC, a division of Nedbank Limited Reg No 1951/000009/06. Authorised financial services and registered credit provider (NCRCP16). Errors and omissions excepted.`,
    specs: [
      {
        title: 'Seating',
        feature: '7 seater and 11 seater configurations'
      },
      {
        title: '80 litres',
        feature: 'Fuel tank'
      },
      {
        title: 'EBD',
        feature: 'Electronic Brakeforce Distribution'
      },
      {
        title: 'Fuel',
        feature: 'Diesel'
      }
    ],
  }
}

export const tmpDealk2500 = {
  title: ' Get a great deal on the Kia K2500',
  model: 'Kia K2500',
  infoMedia: {
    large: CarImages.k2500Large,
    small: CarImages.k2500Small,
    medium1: CarImages.k2500Medium1,
    medium2: CarImages.k2500Medium2,
  },
  carInfo: {
    mainTitle: 'New Kia K2500.',
    introContent: `Give your company that extra 'edge' with the new KIA K2500 that is built to meet all of your needs! The new KIA K2500 has been designed for efficiency and reliability. An easy-access rear gate chain lets you load and unload the KIA K2500 quickly and without any hassle. Design inclusions like a rugged build and the spare wheel that is conveniently attached to the undercarriage, mean that this practical workhorse can handle anything in its way.`,
    subTitle1: 'Power Without Shortages.',
    subContent1: 'People rely on you. The K2-series feels the same way. Making sure it never lets you down was the first thought in the mind of the Kia engineers. The K2700 has the perfect workhorse engine-a 2.7 litre, 4-cylinder, J2 diesel power plant that delivers 62kW of power at 4150 rpm and 165 Nm of torque at 2400 rpm.',
    subTitle2: 'Make Yourself Comfortable, Your K2-Series Already Is.',
    subContent2: 'A man needs room to work, and that’s true even before you get to work. No matter how big the job, the new K2500 has a spacious cabin that will never feel small. The seats are designed to provide maximum comfort and support for people of all shapes and sizes.',
    subTitle3: 'If You Don”t Like It Tough, Tough.',
    subContent3: 'Hard work isn’t a one-day affair. That’s why we have a wraparound cover to protect the chain from corrosion and maintain lifelong use. Folding-type stopper, Safely props up longer items that extend beyond the bed. Easily operated catcher, Has a better grip thanks to its ergonomic handle and an inner spring to ensure smooth movement.'
  },
  gallery: [
    CarImages.k2500Gallery1,
    CarImages.k2500Gallery2,
    CarImages.k2500Gallery3,
    CarImages.k2500Gallery4,
    CarImages.k2500Gallery5,
    CarImages.k2500Gallery6,
  ],
  financeOptions: [
    {
      variant: 'Kia K2500 Workhorse',
      variantId: 121,
      estimatedPayments: 345995,
      monthlyInstallment: 3650,
      id: 0
    }
  ],
  dealDetails: {
    purchasePrice: 345995,
    monthlyInstallment: 3650,
    term: 72,
    linkedInterestRate: 9,
    deposit: 10,
    balloonPayment: 40,
    termsAndConditions: `Excludes on the road costs, bank monthly service fee of R69 and includes one off initiation fee of R1140. Deal is subject to the approval terms and conditions of MFC, a division of Nedbank Limited Reg No 1951/000009/06. Authorised financial services and registered credit provider (NCRCP16). Errors and omissions excepted.`,
    specs: [
      {
        title: '9.4 l/100km',
        feature: 'Average Fuel Consumption'
      },
      {
        title: '65 litres',
        feature: 'Fuel tank'
      },
      {
        title: 'Gearbox',
        feature: 'Manual '
      },
      {
        title: 'EBD',
        feature: 'Electronic Brakeforce Distribution'
      }
    ],
  }
}

export const tmpDealpicanto = {
  title: ' Get a great deal on the Kia Picanto',
  model: 'Kia Picanto',
  infoMedia: {
    large: CarImages.picantoLarge,
    small: CarImages.picantoSmall,
    medium1: CarImages.picantoMedium1,
    medium2: CarImages.picantoMedium2,
  },
  carInfo: {
    mainTitle: 'The city belongs to you',
    introContent: `If the city makes you feel young and alive, and you're eager to explore new places, you've found a soulmate in the new Picanto. Compact on the outside, roomy on the inside, and full of big ideas. With its dynamic performance and agile maneuverability, the Picanto turns a neighborhood into its very own playground`,
    subTitle1: 'True style lies within',
    subContent1: `It may be a compact car, but the Picanto's stylish interior gives you all the space you need to dash about town in comfort. And with ergonomically sound layout that places command for driving, entertainment and temperature control at your fingertips, you'll never have to take your eyes off the road.`,
    subTitle2: '8-inch Floating Touch Screen',
    subContent2: 'Whilst inside, you’ll want for nothing with a sporty leather steering wheel giving you all the performance cues you need, an 8-inch floating infotainment touch screen. The classy infotainment system, with the 8” high-definition multimedia screen with Apple Carplay and Android Auto',
    subTitle3: 'Awesome is. Awesome does.',
    subContent3: 'The updated top-of-the-range Picanto X-Line…really is at the top of its game. The dynamic front bumper with integrated fog lamps makes a true statement of intent, as does the sleek contoured radiator grille. Likewise, from behind, the X-Line leaves others wanting, with its stand-out rear bumper, integrated exhaust, and impressive 15” alloy wheels.'
  },
  gallery: [
    CarImages.picantoGallery1,
    CarImages.picantoGallery2,
    CarImages.picantoGallery3,
    CarImages.picantoGallery4,
    CarImages.picantoGallery5,
    CarImages.picantoGallery6,
  ],
  financeOptions: [
    {
      variant: 'Kia Picanto 1.0 Start Manual',
      modelId: 72,
      estimatedPayments: 187995,
      monthlyInstallment: 2628,
      id: 0
    }
  ],
  dealDetails: {
    purchasePrice: 187995,
    monthlyInstallment: 2628,
    term: 72,
    linkedInterestRate: 9,
    deposit: 0,
    balloonPayment: 40,
    termsAndConditions: `Excludes on the road costs, bank monthly service fee of R69 and includes one off initiation fee of R1140. Deal is subject to the approval terms and conditions of MFC, a division of Nedbank Limited Reg No 1951/000009/06. Authorised financial services and registered credit provider (NCRCP16). Errors and omissions excepted.`,
    specs: [
      {
        title: '4.9 - 6 l/100km',
        feature: 'Average Fuel Consumption'
      },
      {
        title: '35 Litres',
        feature: 'Fuel tank'
      },
      {
        title: 'ABS',
        feature: 'Anti-lock Braking System'
      },
      {
        title: 'ISOFIX',
        feature: 'Child Seat Anchors'
      }
    ],
  }
}

export const tmpDealpicantoRunner = {
  title: ' Get a great deal on the Picanto Runner',
  model: 'Picanto Runner',
  infoMedia: {
    large: CarImages.picantoRunnerLarge,
    small: CarImages.picantoRunnerSmall,
    medium1: CarImages.picantoRunnerMedium1,
    medium2: CarImages.picantoRunnerMedium2,
  },
  carInfo: {
    mainTitle: 'Introducing the New Kia Runner',
    introContent: 'Dynamite Comes in Small Packages. Kia understands that not all payloads are the same and that’s why they’ve designed the innovative Kia Picanto Runner - a delivery vehicle that’s compact but capable and remarkably cost-effective. Packed with performance and advanced functionality, this nifty new Picanto is geared up and ready to explore every city street.',
    subTitle1: 'It’s Time to Run These Streets',
    subContent1: 'This sassy hatchback has been re-imagined as an urban delivery vehicle that provides the cost-effective yet comfortable, safe and fun experience that Picanto drivers are familiar with while incorporating a practical load bay that makes this workhorse such a pleasure to operate.',
    subTitle2: 'Comfort & Peace of Mind',
    subContent2: 'With attention to detail that ensures driver safety and comfort, the 350kg payload capacity is an added bonus that makes this workhorse such an attractive option. Plus, with its unlimited-kilometre warranty and Kia Fleet Care offering, the Kia Picanto Runner is fully-equipped to achieve anything you can imagine.',
    subTitle3: 'Power & Safety',
    subContent3: 'Packed with 49 kW horsepower and pushing out 95 Nm torque, the Kia Runner is agile and ready to tackle the task of navigating any urban setting with fuel-efficiency that is difficult to beat. The Runner is designed to protect you from hazards. It’s finely-tuned ABS brakes and specialised tyres.'
  },
  gallery: [
    CarImages.picantoRunnerGallery1,
    CarImages.picantoRunnerGallery2,
    CarImages.picantoRunnerGallery3,
    CarImages.picantoRunnerGallery4,
    CarImages.picantoRunnerGallery5,
  ],
  financeOptions: [
    {
      variant: 'Kia Picanto Runner 1.0 Panel Van',
      modelId: 74,
      estimatedPayments: 203995,
      monthlyInstallment: 2899,
      id: 0
    }
  ],
  dealDetails: {
    purchasePrice: 203995,
    monthlyInstallment: 2899,
    term: 72,
    linkedInterestRate: 9,
    deposit: 0,
    balloonPayment: 40,
    termsAndConditions: `Excludes on the road costs, bank monthly service fee of R69 and one off initiation fee of R1207.50. Deal is subject to the approval terms and conditions of MFC, a division of Nedbank Limited Reg No 1951/000009/06. Authorised financial services and registered credit provider (NCRCP16). Errors and omissions excepted.`,
    specs: [
      {
        title: '4.9 l/100km',
        feature: 'Average Fuel Consumption'
      },
      {
        title: '35 litres',
        feature: 'Fuel tank'
      },
      {
        title: 'ABS',
        feature: 'Anti-lock Braking System'
      },
      {
        title: 'Steering wheel',
        feature: 'Multi functional with Audio and Bluetooth'
      }
    ],
  }
}

export const tmpDealrio = {
  title: ' Get a great deal on the Kia Rio',
  model: 'Kia Rio',
  infoMedia: {
    large: CarImages.rioLarge,
    small: CarImages.rioSmall,
    medium1: CarImages.rioMedium1,
    medium2: CarImages.rioMedium2,
  },
  carInfo: {
    mainTitle: 'Bigger, bolder and poised for adventure.',
    introContent: 'The all-new Kia RIO makes an entrance with influential styling, loads of space, get up and performance as well as an all-round extraordinary driving experience that exudes charm and confidence. It’s more than a city car. It’s a city-to-city car.',
    subTitle1: 'Face the day with confidence.',
    subContent1: 'The all-new RIO is more than just the attractive new car on your street. With comfortable seats, surprising versatility and dependability backed by a 5-year unlimited kilometre warranty, it’s with you for the long haul.',
    subTitle2: 'A position of heightened control.',
    subContent2: 'The CRETA sets a new standard for practicality and safety. The executive spec comes with LED lights* to make sure you see and enjoy every part of your journey.',
    subTitle3: 'Single-minded obsession - Safety.',
    subContent3: 'Even the most sculpted car has one paramount duty: to protect the people inside it and around it. The all-new Rio features intelligent active-safety technology and substantial passive-safety enhancements to protect people.'
  },
  gallery: [
    CarImages.rioGallery1,
    CarImages.rioGallery2,
    CarImages.rioGallery3,
    CarImages.rioGallery4,
    CarImages.rioGallery5,
    CarImages.rioGallery6,
  ],
  financeOptions: [
    {
      variant: 'Kia Rio 1.2 LS Manual',
      modelId: 75,
      estimatedPayments: 280995,
      monthlyInstallment: 3766,
      id: 0
    }
  ],
  dealDetails: {
    purchasePrice: 280995,
    monthlyInstallment: 3766,
    term: 72,
    linkedInterestRate: 9,
    deposit: 0,
    balloonPayment: 40,
    termsAndConditions: `Excludes on the road costs, bank monthly service fee of R69 and includes one off initiation fee of R1140. Deal is subject to the approval terms and conditions of MFC, a division of Nedbank Limited Reg No 1951/000009/06. Authorised financial services and registered credit provider (NCRCP16). Errors and omissions excepted.`,
    specs: [
      {
        title: 'Connection',
        feature: 'Radio/RDS/MP3/Aux/USB Connection'
      },
      {
        title: '45 litres',
        feature: 'Fuel tank'
      },
      {
        title: 'ABS',
        feature: 'Anti-lock Braking System'
      },
      {
        title: 'Electric windows',
        feature: 'FRONT + REAR'
      }
    ],
  }
}

export const tmpDealseltos = {
  title: ' Get a great deal on the Kia Seltos',
  model: 'Kia Seltos',
  infoMedia: {
    large: CarImages.seltosLarge,
    small: CarImages.seltosSmall,
    medium1: CarImages.seltosMedium1,
    medium2: CarImages.seltosMedium2,
  },
  carInfo: {
    mainTitle: 'ARRIVE HAPPY IN THE NEW KIA SELTO',
    introContent: 'With its eye-catching exterior styling, bold headlamps and muscular stance, the all-new Kia Seltos is a compact SUV like no other. Not that you would expect anything less from Kia: with one of the most awarded design teams in the world, state-of-the-art production technology and world-class quality credentials, the Seltos is the car you’ve been waiting for.',
    subTitle1: 'DRIVING ON SUNSHINE',
    subContent1: 'Whether you’re conquering the urban jungle or exploring the great outdoors, the all-new Kia Seltos is up for any challenge. And with seven amazing exterior colours to choose from – including three exciting two-tone options – your car will match your jovial mood come rain or shine.',
    subTitle2: 'COME JOIN THE JOYRIDE',
    subContent2: 'Seltos EX comes standard with high quality, durable cloth upholstery, while the EX+ will cosset you in comfortable leather seats. Seltos GT Line is equipped with an exclusive leather upholstery incorporating GT Line embroidery. The Kia Seltos comes standard with an 8-inch colour touchscreen infotainments system.',
    subTitle3: 'ARRIVING HAPPY',
    subContent3: 'With its striking exterior accents, the Seltos GT-Line stands apart with a personality all its own. Racing-inspired touches, like the D-cut steering wheel and the stainless pedals with easyto-grip rubber nubs, help to make the Seltos GT-Line as exciting to drive as it is to look at.'
  },
  gallery: [
    CarImages.seltosGallery1,
    CarImages.seltosGallery2,
    CarImages.seltosGallery3,
    CarImages.seltosGallery4,
    CarImages.seltosGallery5,
    CarImages.seltosGallery6,
  ],
  financeOptions: [
    {
      variant: 'Kia Seltos 1.6 EX',
      modelId: 76,
      estimatedPayments: 397995,
      monthlyInstallment: 4266,
      id: 0
    }
  ],
  dealDetails: {
    purchasePrice: 397995,
    monthlyInstallment: 4266,
    term: 72,
    linkedInterestRate: 9,
    deposit: 15.81,
    balloonPayment: 35,
    termsAndConditions: `Excludes on the road costs, bank monthly service fee of R69 and includes one off initiation fee of R1140. Deal is subject to the approval terms and conditions of MFC, a division of Nedbank Limited Reg No 1951/000009/06. Authorised financial services and registered credit provider (NCRCP16). Errors and omissions excepted.`,
    specs: [
      {
        title: 'ISOFIX',
        feature: 'Secure your Child'
      },
      {
        title: '50 litres',
        feature: 'Fuel tank'
      },
      {
        title: 'ABS',
        feature: 'Anti-lock Braking System'
      },
      {
        title: 'Electric windows',
        feature: 'FRONT + REAR'
      }
    ],
  }
}

export const tmpDealsonet = {
  title: ' Get a great deal on the Kia Sonet',
  model: 'Kia Sonet',
  infoMedia: {
    large: CarImages.sonetLarge,
    small: CarImages.sonetSmall,
    medium1: CarImages.sonetMedium1,
    medium2: CarImages.sonetMedium2,
  },
  carInfo: {
    mainTitle: 'Begin Your SUV Life',
    introContent: 'Be inspired by the new Kia Sonet compact SUV. Start with its refined and dynamic appearance. Add a stylish, connected, and spacious cabin. Enjoy simple controls and forward-thinking technology. The compact and classy Sonet means journeys full of possibility and discovery.',
    subTitle1: 'Start With Style',
    subContent1: 'Along with its cross-over capabilities, the new Sonet features sporty SUV contours and trim elements. A versatile rear hatch makes every journey a breeze. Stylish but fun, functional but future forward.',
    subTitle2: 'Get comfortable. Stay connected.',
    subContent2: 'Begin every journey from a commanding height, with plenty of room to stretch. Enjoy refreshingly simple connectivity to help you stay informed and entertained. Get moving with sufficient comfort and technology to keep your mind at ease.',
    subTitle3: 'Precision-enhanced simplicity',
    subContent3: 'A well-matched drivetrain offers reliable and invigorating performance and handling around town. Safety and performance are integrated better than ever before thanks to computational power and a new platform design.'
  },
  gallery: [
    CarImages.sonetGallery1,
    CarImages.sonetGallery2,
    CarImages.sonetGallery3,
    CarImages.sonetGallery4,
    CarImages.sonetGallery5,
    CarImages.sonetGallery6,
  ],
  financeOptions: [
    {
      variant: 'Kia Sonet 1.5 LX',
      modelId: 4904,
      estimatedPayments: 264995,
      monthlyInstallment: 3599,
      id: 0
    }
  ],
  dealDetails: {
    purchasePrice: 264995,
    monthlyInstallment: 3599,
    term: 72,
    linkedInterestRate: 9,
    deposit: 0,
    balloonPayment: 40,
    termsAndConditions: `Excludes on the road costs, bank monthly service fee of R69 and includes one off initiation fee of R1140. Deal is subject to the approval terms and conditions of MFC, a division of Nedbank Limited Reg No 1951/000009/06. Authorised financial services and registered credit provider (NCRCP16). Errors and omissions excepted.`,
    specs: [
      {
        title: 'ATS',
        feature: 'Auto Traction Control'
      },
      {
        title: '45 litres',
        feature: 'Fuel tank'
      },
      {
        title: 'Gearbox',
        feature: 'Manual'
      },
      {
        title: 'EBD',
        feature: 'Electronic Brakeforce Distribution'
      }
    ],
  }
}

export const tmpDealsorento = {
  title: ' Get a great deal on the Kia Sorento',
  model: 'Kia Sorento',
  infoMedia: {
    large: CarImages.sorentoLarge,
    small: CarImages.sorentoSmall,
    medium1: CarImages.sorentoMedium1,
    medium2: CarImages.sorentoMedium2,
  },
  carInfo: {
    mainTitle: 'Equipped to get you right where you want to be.',
    introContent: 'The rugged new Kia Sorento takes you to exciting new places and offers you the equipment needed to make the journey with style and confidence. With its newly elevated headlamps and bonnet, the Sorento cuts a bold and substantial figure on arrival. At the rear, bright LED stop lamps (EX model only) integrated into the tail lamps communicate instantly with the following traffic.',
    subTitle1: 'Surround yourself with intelligent contours.',
    subContent1: 'With its fine craftsmanship, sleek instrumentation and powerful, targeted ventilation, the roomy Sorento interior is a refuge of sophistication.',
    subTitle2: 'Powerful and efficient.',
    subContent2: `The petrol and diesel engines available on the Sorento offer middle and low range torque with smooth, quiet operation. Their enviable fuel economy is aided by ingeniously precise transmissions that balance power and efficiency, whether you're in town or out on the open road.`,
    subTitle3: 'Luxurious interior flexibility',
    subContent3: 'The Sorento interior offers a high standard of comfort and luxury featuring finely crafted seats. With multiple configurations, practicality and versatility are standard in the new Sorento.'
  },
  gallery: [
    CarImages.sorentoGallery1,
    CarImages.sorentoGallery2,
    CarImages.sorentoGallery3,
    CarImages.sorentoGallery4,
    CarImages.sorentoGallery5,
    CarImages.sorentoGallery6,
  ],
  financeOptions: [
    {
      variant: 'Kia Sorento 2.2 CRDI 4x2 LX 7 Seater AT',
      modelId: 78,
      estimatedPayments: 679995,
      monthlyInstallment: 6714,
      id: 0
    }
  ],
  dealDetails: {
    purchasePrice: 679995,
    monthlyInstallment: 6714,
    term: 72,
    linkedInterestRate: 9,
    deposit: 10.79,
    balloonPayment: 40,
    termsAndConditions: `Excludes on the road costs, bank monthly service fee of R69 and includes one off initiation fee of R1140. Deal is subject to the approval terms and conditions of MFC, a division of Nedbank Limited Reg No 1951/000009/06. Authorised financial services and registered credit provider (NCRCP16). Errors and omissions excepted.`,
    specs: [
      {
        title: 'Parking Assist Sensors (PDC)',
        feature: 'FRONT + REAR'
      },
      {
        title: '80 litres',
        feature: 'Fuel tank'
      },
      {
        title: 'ABS',
        feature: 'Anti-lock Braking System'
      },
      {
        title: 'Electric windows',
        feature: 'FRONT + REAR'
      }
    ],
  }
}

export const tmpDealsportage = {
  title: ' Get a great deal on the Kia Sportage',
  model: 'Kia Sportage',
  infoMedia: {
    large: CarImages.sportageLarge,
    small: CarImages.sportageSmall,
    medium1: CarImages.sportageMedium1,
    medium2: CarImages.sportageMedium2,
  },
  carInfo: {
    mainTitle: 'For journeys, not just destinations.',
    introContent: 'The enhanced Kia Sportage is Made to Wow. More breathtakingly distinct design. More cutting-edge technologies. More advanced features to enhance your comfort and safety. Improvements to handling and a stronger body mean an even more refined ride. More reasons to get out and seize each day.',
    subTitle1: 'Eye-catching style.',
    subContent1: `The enhanced Kia Sportage manages to stand out no matter where you are. Weather you'er zipping through the city or making your way off the beaten track, cruising the open road or parked at home, the Sportage is the perfect combination of sportiness and style. The perfect embodiment of Wow.`,
    subTitle2: 'You won’t just see the difference. You’ll feel it.',
    subContent2: `You will want to be very hands-on in the new Kia Sportage. Full of soft touch materials, high-end finishes and the utmost attention to every detail. the interior is just as comfortable as you'd imagine. You will feel right at at home.`,
    subTitle3: 'Every trip will be like a holiday.',
    subContent3: `The Kia Sportage is designed to be comfortable. More than comfortable, designed to make every trip wonderfully convenient, smooth and effortless because journeys are even more fun when they're shared.`
  },
  gallery: [
    CarImages.sportageGallery1,
    CarImages.sportageGallery2,
    CarImages.sportageGallery3,
    CarImages.sportageGallery4,
    CarImages.sportageGallery5,
    CarImages.sportageGallery6,
  ],
  financeOptions: [
    {
      variant: 'Kia Sportage 1.6 GDI Ignite AT',
      modelId: 77,
      estimatedPayments: 452995,
      monthlyInstallment: 4655,
      id: 0
    }
  ],
  dealDetails: {
    purchasePrice: 452995,
    monthlyInstallment: 4655,
    term: 72,
    linkedInterestRate: 9,
    deposit: 9.57,
    balloonPayment: 40,
    termsAndConditions: `Excludes on the road costs, bank monthly service fee of R69 and includes one off initiation fee of R1140. Deal is subject to the approval terms and conditions of MFC, a division of Nedbank Limited Reg No 1951/000009/06. Authorised financial services and registered credit provider (NCRCP16). Errors and omissions excepted.`,
    specs: [
      {
        title: 'EBD',
        feature: 'Electronic Brakeforce Distribution'
      },
      {
        title: '62 litres',
        feature: 'Fuel tank'
      },
      {
        title: 'ABS',
        feature: 'Anti-lock Braking System'
      },
      {
        title: 'SATNAV',
        feature: 'Automotive Navigation System *'
      }
    ],
  }
}

export const tmpDealPegas = {
  title: ' Get a great deal on the Kia Pegas',
  model: 'Kia Pegaso',
  infoMedia: {
    large: CarImages.pegasLarge,
    small: CarImages.pegasSmall,
    medium1: CarImages.pegasMedium1,
    medium2: CarImages.pegasMedium2,
  },
  carInfo: {
    mainTitle: 'The Kia Pegas, The space to move',
    introContent: 'A car that gives you the space to move. Get used to modern design, leg-stretching comfort, and a bigger boot. The Pegas gives you the space you need for inspiring journeys.',
    subTitle1: 'Stylish touches',
    subContent1: 'Stylish touches and simple lines. Dynamic, modern lines meet sharp edged design. Simple never looked this good.',
    subTitle2: 'Connectivity on the go.',
    subContent2: `USB ports front and back so you’re always in touch.
    7-Inch touch screen display audio - A large screen featuring integrated rearview camera, Bluetooth connectivity and Apple CarPlay and Android Auto compatibility.
    2.8-inch LCD cluster - Drive safer and smarter thanks to a variety of driving and vehicle data all located in one place on the high-visibility LCD instrument-cluster.'`,
    subTitle3: `Powerful, fun and efficient.`,
    subContent3: `5-speed manual transmission - Gear ratios perfectly matched to the engine enable smooth shifts during relaxed or vigorous driving.
    4-speed automatic transmission - A smart selector with a comfortable grip gives you access to soft, precise and fuel efficient gear transitions.'`
  },
  gallery: [
    CarImages.pegasGallery1,
    CarImages.pegasGallery2,
    CarImages.pegasGallery3,
    CarImages.pegasGallery4,
    CarImages.pegasGallery5,
    CarImages.pegasGallery6,
  ],
  financeOptions: [
    {
      variant: 'Kia Pegas',
      variantId: 0,
      estimatedPayments: 225995,
      monthlyInstallment: 3199,
      id: 0
    }
  ],
  dealDetails: {
    purchasePrice: 225995,
    monthlyInstallment: 3199,
    term: 72,
    linkedInterestRate: 9,
    deposit: 0,
    balloonPayment: 40,
    termsAndConditions: `Excludes on the road costs, bank monthly service fee of R69 and includes one off initiation fee of R1140. Deal is subject to the approval terms and conditions of MFC, a division of Nedbank Limited Reg No 1951/000009/06. Authorised financial services and registered credit provider (NCRCP16). Errors and omissions excepted.'`,
    specs: [
      {
        title: 'Electric windows',
        feature: 'Front + Rear'
      },
      {
        title: '43 Litres',
        feature: 'Fuel tank'
      },
      {
        title: 'ABS',
        feature: 'Anti-lock Braking System'
      },
      {
        title: 'EBD',
        feature: 'Electronic Brakeforce Distribution'
      }
    ],
  }
}

export const tmpDealK2700 = {
  title: ' Get a great deal on the Kia K2700',
  model: 'Kia K2700',
  infoMedia: {
    large: CarImages.k2700Large,
    small: CarImages.k2700Small,
    medium1: CarImages.k2700Medium1,
    medium2: CarImages.k2700Medium2,
  },
  carInfo: {
    mainTitle: 'New Kia K7500.',
    introContent: `Give your company that extra 'edge' with the new KIA K2700 that is built to meet all of your needs! The new KIA K2700 has been designed for efficiency and reliability. An easy-access rear gate chain lets you load and unload the KIA K2500 quickly and without any hassle. Design inclusions like a rugged build and the spare wheel that is conveniently attached to the undercarriage, mean that this practical workhorse can handle anything in its way.`,
    subTitle1: 'Power Without Shortages.',
    subContent1: 'People rely on you. The K2-series feels the same way. Making sure it never lets you down was the first thought in the mind of the Kia engineers. The K2700 has the perfect workhorse engine-a 2.7 litre, 4-cylinder, J2 diesel power plant that delivers 62kW of power at 4150 rpm and 165 Nm of torque at 2400 rpm.',
    subTitle2: 'Make Yourself Comfortable, Your K2-Series Already Is.',
    subContent2: 'A man needs room to work, and that’s true even before you get to work. No matter how big the job, the new K2500 has a spacious cabin that will never feel small. The seats are designed to provide maximum comfort and support for people of all shapes and sizes.',
    subTitle3: 'If You Don”t Like It Tough, Tough.',
    subContent3: 'Hard work isn’t a one-day affair. That’s why we have a wraparound cover to protect the chain from corrosion and maintain lifelong use. Folding-type stopper, Safely props up longer items that extend beyond the bed. Easily operated catcher, Has a better grip thanks to its ergonomic handle and an inner spring to ensure smooth movement.'
  },
  gallery: [
    CarImages.k2700Gallery1,
    CarImages.k2700Gallery2,
    CarImages.k2700Gallery3,
    CarImages.k2700Gallery4,
    CarImages.k2700Gallery5,
    CarImages.k2700Gallery6,
  ],
  financeOptions: [
    {
      variant: 'Kia K2700 Workhorse',
      estimatedPayments: 297995,
      monthlyInstallment: 3165,
      id: 0
    }
  ],
  dealDetails: {
    purchasePrice: 297995,
    monthlyInstallment: 3165,
    term: 72,
    linkedInterestRate: 9,
    deposit: 10.5,
    balloonPayment: 40,
    termsAndConditions: `Excludes on the road costs, bank monthly service fee of R69 and includes one off initiation fee of R1140. Deal is subject to the approval terms and conditions of MFC, a division of Nedbank Limited Reg No 1951/000009/06. Authorised financial services and registered credit provider (NCRCP16). Errors and omissions excepted.`,
    specs: [
      {
        title: '8.2 l/100km',
        feature: 'Average Fuel Consumption'
      },
      {
        title: '60 litres',
        feature: 'Fuel tank'
      },
      {
        title: 'Gearbox',
        feature: 'Manual'
      },
      {
        title: 'EBD',
        feature: 'Electronic Brakeforce Distribution'
      }
    ],
  }
}