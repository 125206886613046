import { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URI, PAGE_SIZE, DEALERID } from '../shared/Constants'

export default function VehicleController() {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [vehicles, setVehicles] = useState([])
  const [total, setTotal] = useState(false)

  useEffect(() => {
    let cancel
    setLoading(true)
    setError(false)
    setVehicles([])
    const params = {
      pageNumber: 1,
      dealerId: DEALERID,
      pageSize: PAGE_SIZE
    }

    axios({
      method: 'GET',
      url: `${BASE_URI}/dealermodels`,
      params,
      cancelToken: new axios.CancelToken(c => cancel = c)
    }).then((response) => {
      setVehicles(response.data?.list)
      setTotal(response.data?.list.length > 0)
      setLoading(false)
    }).catch(error => {
      if (axios.isCancel(error)) return
      setLoading(false)
    })
    return () => cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { loading, error, vehicles, total }
}