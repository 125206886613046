/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { BASE_URI, DEALERID, DEALERNAME } from "./Constants"
import axios from "axios";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Switch } from 'formik-material-ui'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from '@material-ui/core/Box';
import { useSnackbar } from "notistack";
import { CircularProgress } from "material-ui";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import { NavLink as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  stepPadding: {
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    marginTop: "2rem",
  },
  stepPaddingBelow: {
    marginRight: "0.5rem",
    marginTop: "0.5rem",
  },
  stepPaddingButton: {
    marginTop: "0.5rem",
  },
  stepPaddingTitle: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  card: {
    width: "100%"
  },
  flex: {
    display: "flex"
  },

  button: {
    width: "100%",
  },
  backButton: {
    marginTop: "2rem",
  },
  image: {
    minWidth: "100%",
    height: 550,
  },
  uppercase: {
    textTransform: 'uppercase'
  },
}));

export default function ContactContainer(props) {
  const classes = useStyles();
  const [contactMe, setContactMe] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState(false)

  let phoneRegExp = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;

  let isCashBuyer = props.isCashBuyer;
  let leadTypeId = props.leadTypeId;

  const values = {
    name: '',
    phone: '',
    email: ''
  }

  const validationSchema = Yup.object({
    name: Yup.string("Enter a name").required(
      "Name is required"
    ),
    email: Yup.string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    phone: Yup.string("Enter your phone number")
      .required("Phone number is required")
      .matches(phoneRegExp, "Phone number is not valid")
  });

  const checkValid = (validForm) => {
    return (validForm && contactMe)
  }

  const createVehicleLead = (values) => {

    let vehicleParam = {}
    vehicleParam.LeadId = values.leadId;
    vehicleParam.VariantId = props.variantId;

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axios({
      method: 'POST',
      url: `${BASE_URI}/leadVehicles`,
      data: vehicleParam,
      cancelToken: source.token
    }).then((responseSecond) => {
      setLoader(false);
      //enqueueSnackbar(`Successfully sent information`, { variant: 'success' });

    }).catch(error => {
      if (axios.isCancel(error)) return
      enqueueSnackbar("Unable to get complete the request", { variant: 'error' });
      //setLoader(false);
    })
  }

  function createLead(values) {

    setLoader(true);

    let params = {}

    params.leadStatusId = 1
    params.dealerId = DEALERID
    params.name = values.name
    params.firstName = values.name;
    params.lastName = '';
    params.phoneNumber = values.phone
    params.emailAddress = values.email
    params.leadSourceId = 1
    params.TypeCode = "NEW"
    params.notes = props.isCashBuyer ? "Cash buyer" : ""
    params.leadTypeId = leadTypeId
    params.dateUpdated = new Date().toISOString();

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axios({
      method: 'POST',
      url: `${BASE_URI}/leads`,
      data: params,
      cancelToken: source.token
    }).then((response) => {

      fbq('track', 'Lead');  // eslint-disable-line

      if (props.variantId) {
        values.leadId = response.data.id;

        createVehicleLead(values);
      }
      else {
        setLoader(false);
        //enqueueSnackbar(`Successfully sent information`, { variant: 'success' });
      }

    }).catch(error => {
      if (axios.isCancel(error)) return
      enqueueSnackbar("Unable to complete the request", { variant: 'error' });
      setLoader(false);
    })
  }

  return (
    <React.Fragment>
      <MuiThemeProvider>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Card className={classes.content} elevation={0}>
              <CardContent>
                <Formik
                  isInitialValid={false}
                  initialValues={values}
                  validationSchema={validationSchema}
                  onSubmit={async (values, actions) => {
                    createLead(values)
                  }}
                >
                  {(props) => {
                    let {
                      values: {
                        name,
                        phone,
                        email
                      },
                      touched,
                      errors,
                      isValid,
                      submitForm,
                      setFieldTouched,
                      setFieldValue
                    } = props;
                    return (
                      <Form>
                        <Grid container className={classes.root} direction="row">
                          <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                          >
                            <TextField
                              id="name"
                              name="name"
                              label="Name"
                              value={name}
                              fullWidth
                              variant="outlined"
                              helperText={touched.name ? errors.name : ""}
                              error={touched.name && Boolean(errors.name)}
                              onChange={(event) => {
                                setFieldValue("name", event.target.value)
                                setFieldTouched("name", true, false);
                              }}
                            />
                          </Grid>
                          <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                            lg={12}>
                            <TextField
                              id="email"
                              name="email"
                              type="email"
                              label="Email"
                              fullWidth
                              value={email}
                              variant="outlined"
                              helperText={touched.email ? errors.email : ""}
                              error={touched.email && Boolean(errors.email)}
                              onChange={(event) => {
                                setFieldValue("email", event.target.value)
                                setFieldTouched("email", true, false);
                              }}
                            />{" "}
                          </Grid>
                          <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                            lg={12}>
                            <TextField
                              id=" phone"
                              name="phone"
                              type="tel"
                              label="Cellphone"
                              fullWidth
                              value={phone}
                              variant="outlined"
                              helperText={touched.phone ? errors.phone : ""}
                              error={touched.phone && Boolean(errors.phone)}
                              onChange={(event) => {
                                setFieldValue("phone", event.target.value)
                                setFieldTouched("phone", true, false);
                              }}
                            />
                          </Grid>
                          <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                            lg={12}>

                            <Typography gutterBottom variant="p" color="textPrimary" fontWeight="500">
                              Parts enquiry
                            </Typography>

                            <Box mb={2}>
                              <TextField
                                id=" phone"
                                name="phone"
                                type="text"
                                fullWidth
                                multiline
                                rows={4}
                                variant="standard"

                              />
                            </Box>
                          </Grid>
                          <Grid item className={classes.flex} xs={12} md={12}
                            lg={12}>
                            <FormControlLabel
                              control={
                                <Field component={Switch}
                                  color="primary"
                                  type="checkbox"
                                  name="contactMe"
                                  onClick={() => {
                                    setContactMe(contactMe ? false : true)
                                  }}
                                />
                              }

                            />
                            <span>I allow {DEALERNAME}  to contact me</span>
                          </Grid>

                          <Grid item className={classes.flex} xs={12} md={12}
                            lg={12}>
                            <FormControlLabel
                              control={
                                <Field component={Switch}
                                  color="primary"
                                  type="checkbox"
                                  name="popi"

                                />
                              }

                            />
                            <span>
                              By submitting this form I agree to the <Link component={RouterLink} to="/terms" className={classes.link}>terms and conditions</Link> and <Link component={RouterLink} to="/privacy" className={classes.link}>privacy policies.</Link>
                            </span>
                          </Grid>
                          <Grid container direction="row">
                            <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                              lg={12}>

                              {!loader &&
                                <Button className={classes.button} variant={"contained"}
                                  color="primary"
                                  disabled={!checkValid(isValid)}
                                  onClick={() => {
                                    submitForm();
                                  }}
                                >
                                  {isCashBuyer ? 'Order Now' : 'Contact Me'}
                                </Button>
                              }
                              {loader == true && (
                                <Button className={classes.button} variant={"contained"}
                                  color="primary"
                                  disabled={true}
                                >
                                  <CircularProgress />
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </MuiThemeProvider>
    </React.Fragment>
  );
}