import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import VehicleControllerHome from "../usedVehiclesHome/VehicleControllerHome";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import UsedVehiclesHome from '../usedVehiclesHome/UsedVehiclesHome';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0, 6),
  },
  flexTop: {
    display: "flex",
    flexDirection: "column!important",
    justifyContent: "space-between",
  },
  center: {
    display: "flex",
    flexDirection: "column!important",
    margin: '0 auto',
  },
  accent: {
    color: "#0089a1",
  },
  titleh: {
    paddingTop: "40px",
    fontSize: '2.8rem',
  },
}));

const UsedCarUsedVehiclesPageHome = () => {
  const history = useHistory();
  const classes = useStyles();
  const filteredData = null;
  const showFilteredData = false;
  const { vehicles } = VehicleControllerHome();

  const onSelectVehicle = (vehicle) => {
    history.push(`/used-vehicles/${vehicle?.stockId}`)
  }

  return (
    <MuiThemeProvider>
      <Container maxWidth="lg" className={classes.content}>
        <Grid className={classes.flexTop}>
          <Typography gutterBottom variant="h4" className={classes.titleh} align="center" color="textPrimary" fontWeight="500" style={{ color: '#404041' }}>
            FEATURED <span className={classes.accent}> USED</span> VEHICLES
          </Typography>
        </Grid>
        <Grid md={12}>
          <UsedVehiclesHome vehicles={showFilteredData ? filteredData : vehicles} onSelectView={onSelectVehicle} />
        </Grid>
      </Container>
    </MuiThemeProvider>
  );
};

export default UsedCarUsedVehiclesPageHome;
