import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import FacebookIcon from '@material-ui/icons/Facebook';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import { DEALERNAME } from '../shared/Constants'
import HeadPhone from '../shared/assets/HeadPhone.svg'
import Chat from '../shared/assets/chat.svg'
import TestDrive from '../shared/assets/2962303.svg'
import Location from '../shared/assets/484167.svg'
import Logo from '../shared/assets/aksons-logo-white.svg'

function Copyright() {
  return (
    <React.Fragment>
      {'© '}
      Copyright {DEALERNAME}
      {' '}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(0),
    backgroundColor: '#ffffff',
    padding: '40px 0px',
    color: '#7C878E',
    fontSize: '14px'
  },
  container: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(0),
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: 'flex',
  },
  icon: {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    color: '#000000'
  },
  list: {
    margin: 0,
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
  footerIconSection: {

  },
  footerIconSectionLink: {
    color: '#ffffff !important'
  },
  footerIconSectionIcon: {
    color: '#7C878E'
  },
  footerLink: {
    color: '#7C878E',
    fontWeight: 'bold',
  },
  footerLinkHeading: {
    color: '#7C878E',
    fontWeight: '500 !important;',
  },
  footerSecton: {
    backgroundColor: '#E7E9E9',
    color: '#15A3B2',
    fontWeight: '500 !important;',
    padding: '15px',
    borderRadius: '10px',
    textAlign: 'center',
    fontSize: '13px',
    marginBottom: '35px',
    marginTop: '35px'
  },
  footerColor: {
    color: 'rgb(21, 163, 178) !important',
  },
  footerFbColor: {
    color: 'rgb(21, 163, 178) !important',
    padding: '10px'
  },
  footerSectonLink: {
    color: '#0089a1',
  },
  footerLogo: {
    width: '45%',
    marginBottom: '17px'
  },
  mbr: {
    marginRight: "10px"
  },
  stickyBarInner: {
    backgroundColor: "#F8F8F8",
    textAlign: "center",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "60px",
    width: "100%",
  },
  stickyBar: {
    display: 'block',
    padding: '20px',
    height: '60px',
    width: '100%'
  },
  stickyBarImage: {
    width: '100%',
    height: '100%'
  },
  card1: {
    backgroundColor: "#0089a1",
    height: "240px",
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  card2: {
    backgroundColor: "#404041",
    height: "240px",
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  card3: {
    backgroundColor: "#0089a1",
    height: "240px",
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  card4: {
    backgroundColor: "#404041",
    height: "240px",
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  svg: {
    width: '80px',
    margin: '0 auto'
  },
  banner: {
    backgroundColor: '#404041',
    padding: '20px',
    display: 'flex',
    justifyContent: 'end'
  },
  LogoHolder: {
    width: "10%"
  }
}));

export default function FooterComponent() {
  const classes = useStyles();
  return (
    <Fragment>
      <Container className={classes.container}>
        <div class="addthis_inline_share_toolbox"></div>
      </Container>
      <Box className={classes.footerIconSection}>
        <Container maxWidth="false" className={classes.container}>
          <Grid container spacing={5} className={classes.footerIconSection}>
            <Grid item xs={12} sm={6} md={3} align="center" className={classes.card1}>
              <Box mb={2} className={classes.svg}>
                <img alt="Headphones" src={HeadPhone} width="100%" />
              </Box>
              <Typography className={classes.footerIconSectionLink}>Call us now</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} align="center" className={classes.card2}>
              <Box mb={2} className={classes.svg}>
                <img alt="Chat bubble" src={Chat} width="100%" />
              </Box>
              <Typography className={classes.footerIconSectionLink}>Talk To Us</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} align="center" className={classes.card3}>
              <Box mb={2} className={classes.svg}>
                <img alt="Car Icon" src={TestDrive} width="100%" />
              </Box>
              <Typography className={classes.footerIconSectionLink}>Test drive</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} align="center" className={classes.card4}>
              <Box mb={2} className={classes.svg}>
                <img alt="Map pin icon" src={Location} width="100%" />
              </Box>
              <Typography className={classes.footerIconSectionLink}>Nationwide delivery</Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Typography component="footer" className={classes.root}>
        <Container className={classes.container}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h6" marked="left" gutterBottom>
                <span className={classes.footerLink}>Dealer Locations</span>
              </Typography>
              <ul className={classes.list}>
                <li className={classes.listItem}>

                  <Box className={classes.footerLinkHeading}>
                    A:Pietermaritzburg,<br /> 302 Hoosen Haffajee street, <br /> T: 033 392 8500
                  </Box> <br />
                  <Box className={classes.footerLinkHeading}>
                    A: Pietermaritzburg North,<br /> 1 Chatterton Road, 3201 <br /> T: 033 342 4600
                  </Box>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6" marked="left" gutterBottom>
                Quick Links
              </Typography>
              <ul className={classes.list}>
                <li className={classes.listItem}>
                  <Link className={classes.footerColor} href="/cookies">Cookie Policy</Link>
                </li>
                <li className={classes.listItem}>
                  <Link className={classes.footerColor} href="/terms">Terms & Conditions</Link>
                </li>
                <li className={classes.listItem}>
                  <Link className={classes.footerColor} href="/privacy">Privacy Policy</Link>
                </li>
                <li className={classes.listItem}>
                  <Link className={classes.footerColor} href="/requestergws">Popi Request</Link>
                </li>
              </ul>
              <Grid item className={classes.icons}>
                <IconButton className={classes.icon}>
                  <Link href="https://www.facebook.com/people/InspectaCar-Aksons-PMB/100040158430985/" className={classes.footerFbColor} target="_blank">
                    <FacebookIcon />
                  </Link>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Typography>
      <div className={classes.banner}>
        <div className={classes.LogoHolder}>
          <img alt="AskonsiCar Logo" src={Logo} width="100%" />
        </div>
      </div>
      <Grid item>
        <Box style={{ color: '#15A3B2', fontSize: '12px', marginTop: '10px' }}>
          <Copyright />
        </Box>
        <Box style={{ textAlign: 'right', display: 'block', color: '#15A3B2', marginTop: '-20px', fontSize: '12px' }}>
          {process.env.REACT_APP_BUILD_NUMBER || 'DEV'}
        </Box>
      </Grid>
    </Fragment>
  );
}
