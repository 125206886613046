import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    justifyContent: "space-evenly",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
  },
  imgHolder: {
    padding: "50px",
    minWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "150px",
  },
  Carousel: {
    //transform: (slidePosition) => `translate(${slidePosition}%, 0px)`,
    width: "100%",
    justifyContent: "space-evenly",
    alignItems: "center",
    transition: "0.5s all ease-in",
  },
  nextbtn: {
    background: 'transparent',
    border: 'black 3px solid',
    borderRadius: '50%',
    right: "0",
    padding: "10px",
    color: "black",
    opacity: "0.5",
    transition: "0.1s all ease-in",
    "&:hover": {
      background: 'transparent',
      border: 'white 3px solid',
      color: "white",
      opacity: "0.8",
      cursor: 'pointer'
    },
  },
  backbtn: {
    background: 'transparent',
    border: 'black 3px solid',
    borderRadius: '50%',
    left: "0",
    padding: "10px",
    color: "black",
    opacity: "0.5",
    transition: "0.1s all ease-in",
    "&:hover": {
      background: 'transparent',
      border: 'white 3px solid',
      color: "white",
      opacity: "0.8",
      cursor: 'pointer'
    },
  },
  btnHolder: {
    display: "flex",
    position: "absolute",
    width: "97%",
    justifyContent: "space-between",
  },
  img: {
    filter: 'brightness(0.9)',
  }
}));

export default function SharedCarousel(props) {

  const [currentSlide, setCurrentSlide] = useState(props?.slides[0] || null);

  const currentSlideIndexRef = useRef(0);
  const carouselPausedRef = useRef(false);

  const classes = useStyles(currentSlideIndexRef.current);

  useEffect(() => {
    if (props?.slides?.length > 0) {
      const interval = setInterval(() => {
        carouselScroll();
      }, 5000);

      return () => clearInterval(interval);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.slides]);

  const carouselScroll = () => {

    if (carouselPausedRef.current === true) {
      return;
    }

    handleNextSlide();

  };

  const handleVideoStart = () => {

    carouselPausedRef.current = true;

  };

  const handleVideoEnded = (e) => {

    carouselPausedRef.current = false;

  };

  const handleNextSlide = () => {

    let newIndex = 0;

    if (currentSlideIndexRef.current < props.slides.length - 1) {
      newIndex = currentSlideIndexRef.current + 1;
    }

    currentSlideIndexRef.current = newIndex;
    setCurrentSlide(props.slides[newIndex]);
    handleVideoEnded();

  };

  const handlePreSlide = () => {

    let newIndex = props.slides.length - 1;

    if (currentSlideIndexRef.current > 0) {
      newIndex = currentSlideIndexRef.current - 1;
    }

    currentSlideIndexRef.current = newIndex;
    setCurrentSlide(props.slides[newIndex]);
    handleVideoEnded();

  };

  const handleDrag = (e) => {
    // console.log("e",e)
    // if(slideIndex > 0){
    //   setSlideActive(true);
    //   setSlideIndex(slideIndex - 1)
    // }
  }

  return (
    <div className={classes.root}>

      <div className={classes.Carousel} onDrag={handleDrag}>
        <a href={currentSlide?.targetUrl ?? "/apply-for-finance"}>
          {currentSlide?.content}
          {
            currentSlide?.isVideo
              ? <video controls={false} width="100%" autoPlay muted onEnded={handleVideoEnded} onPlay={handleVideoStart}>
                <source type='video/mp4' src={currentSlide?.img} />
                <source type="video/webm" src={currentSlide?.img} />
                <source type="video/ogg" src={currentSlide?.img} />
                Your browser does not support this video.
              </video>
              : <img alt="" className={classes.backgroundImage} width="100%" tag="div" src={currentSlide?.img ?? currentSlide} />
          }
        </a>
      </div>

      <div className={classes.btnHolder}>
        <button className={classes.backbtn} onClick={handlePreSlide}>
          <ChevronLeftIcon style={{ fontSize: 40 }} />
        </button>
        <button className={classes.nextbtn} onClick={handleNextSlide}>
          <ChevronRightIcon style={{ fontSize: 40 }} />
        </button>
      </div>

    </div>
  );

}